const restrictedUserNames = [
    'praveen.rajagopal@coxautoinc.com',
    'ben.harrington@coxautoinc.com',
    'charlie.alderete@coxautoinc.com',
    'dedra.fabre@coxautoinc.com',
    'joseph.peacock@coxautoinc.com',
    'nur.haque@coxautoinc.com',
    'srikanth.hari@coxautoinc.com',
    'sumeet.parashar@coxautoinc.com'
]

export function isUserHasAccess(userName, bffEnv) {
    if (bffEnv === 'prod' && restrictedUserNames.includes(userName)) {
        return false
    }
    return true
}
