import { React } from 'react'
import { PageHeader } from '../components/PageHeader'
import { Container } from '../components/Container'
import { FeeIssuesSearchResults } from '../components/feeissues/FeeIssuesSearchResults'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { auctionDisplayWithCode } from '../utils/auction.util'
import { useStaticDataContext } from '../context/StaticDataContext'
import { Button } from '@interstate/components/Button'

export function FeeIssuesReportSearchResultsView() {
    const staticData = useStaticDataContext()
    const navigate = useNavigate()
    let [searchParams] = useSearchParams()
    const auctionCode = searchParams.get('auctionCode')
    const saleNumber = searchParams.get('saleNumber')
    let auditSource = searchParams.get('auditSource')
    if (!auditSource) {
        auditSource = 'vehicle-fee-processor-lambda'
    }
    function displayAuctionName() {
        return `Fee Reconciliation Report for ${saleNumber} ${auctionDisplayWithCode(auctionCode, staticData.auctionInfoMap)}`
    }
    function handleBack() {
        navigate('/boc/feeIssuesReportSearch')
    }
    return (
        <Container>
            <div>
                <Button buttonStyle={'secondary'} onClick={handleBack}>
                    Back
                </Button>
            </div>
            <div>&nbsp;</div>
            <PageHeader titleValue={displayAuctionName()} />
            <FeeIssuesSearchResults
                auctionCode={auctionCode}
                saleNumber={saleNumber}
                auditSource={auditSource}
            />
        </Container>
    )
}
