import React from 'react'
import { PageHeader } from '../components/PageHeader'
import { Container } from '../components/Container'
import { FeeIssuesSummaryReportTable } from '../components/feeissues/FeeIssuesSummaryReportTable'

export function FeeIssuesSummaryReportView() {
    return (
        <Container>
            <PageHeader titleValue="Fee Reconciliation Summary Report" />
            <FeeIssuesSummaryReportTable />
        </Container>
    )
}
