import React, { useState } from 'react'
import { useAuth } from '../../auth/AuthProvider'
import { isDevEnv } from '../../utils/environments'
import { Container } from '../../components/Container'
import { CheckBox } from '@interstate/components/CheckBox'
import { Button } from '@interstate/components/Button'
import { PageHeader } from '../../components/PageHeader'
import { PatTextInput } from '../../components/common/PatTextInput'
import { MockUserForm } from '../../components/MockUserForm'

export function LoginView() {
    const authService = useAuth()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    if (authService.isAuthenticated()) {
        return <div>Authenticated</div>
    }

    return (
        <Container>
            {!authService.hasStateToken() && (
                <form method="POST" action="/auth/login/password">
                    <PageHeader titleValue="Sign In" />

                    <PatTextInput
                        id="email"
                        label="Email address"
                        name="email"
                        data-testid="email"
                        placeholder="email"
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                    />
                    <input type="hidden" name="username" value={username} />

                    <PatTextInput
                        id="passwprd"
                        label="Password"
                        name="pass"
                        data-testid="password"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />
                    <input type="hidden" name="password" value={password} />

                    <CheckBox
                        id="checkbox-1-id"
                        label="Remember this device"
                        name="rememberMe"
                        sx={{
                            margin: '18px'
                        }}
                        value="1"
                    />

                    <Button buttonStyle="primary" type="submit">
                        Sign In
                    </Button>
                    <br />
                    <br />
                    <a href="/auth/sso">SSO</a>
                </form>
            )}

            {isDevEnv() && (
                <div>
                    <MockUserForm username="mock-user@rocket" />
                    <MockUserForm username="edit-boc-test@coxautoinc.com" />
                    <MockUserForm username="edit-pt-test@coxautoinc.com" />
                    <MockUserForm username="edit-ap-test@coxautoinc.com" />
                    <MockUserForm username="location.tester@cox.com" />
                </div>
            )}
        </Container>
    )
}
