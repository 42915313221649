import React, { useState } from 'react'
import { useStaticDataContext } from '../../context/StaticDataContext'
import { Button } from '@interstate/components/Button'
import { getDateFromIsoDateInFormat } from '../../utils/date.helper.util'
import { getFeeIssuesSummary } from '../../service/auditService'
import { Table } from '@interstate/components/Table'
import { PatDatePicker } from '../common/PatDatePicker'
import pLimit from 'p-limit'
import {
    auctionDisplayWithCode,
    auctionSaleDays
} from '../../utils/auction.util'
import { LocalSpinner } from '../common/LocalSpinner'
import { useAuth } from '../../auth/AuthProvider'
import { PAT_ROLE } from '../../resources/enum.constants'

export function FeeIssuesSummaryReportTable() {
    const authService = useAuth()
    const role = authService?.principal?.role
    const auditSource = 'vehicle-fee-processor-lambda'
    const saleDateFormat = 'YYYY-MM-DD'
    const staticData = useStaticDataContext()
    const convertedAuctionsList = staticData.convertedAuctions
    // const convertedAuctionsList = wave0Auctions
    //     .concat(wave1Auctions)
    //     .concat(wave2Auctions)
    const [saleDate, setSaleDate] = useState(new Date())
    const [summaryDataByAuction, setSummaryDataByAuction] = useState({})

    function getSummaryData() {
        return Object.values(summaryDataByAuction)
    }

    function getSaleDateForApi() {
        return getDateFromIsoDateInFormat(saleDate, saleDateFormat)
    }

    function resetAllData(triggerSpinner) {
        let newSummaryDataByAuction = {}
        convertedAuctionsList.forEach((auctionCode) => {
            newSummaryDataByAuction[auctionCode] = {
                auctionCode: auctionCode,
                totalVehicleCount: 0,
                feeMisMatchBuyerFinalize: 0,
                feeMisMatchNotBuyerFinalize: 0,
                saleKeyList: [],
                showSpinner: triggerSpinner
            }
        })
        setSummaryDataByAuction(newSummaryDataByAuction)
    }

    function updateSummaryDataForAuction(
        auctionCode,
        {
            totalVehicleCount,
            feeMisMatchBuyerFinalize,
            feeMisMatchNotBuyerFinalize,
            saleKeyList,
            showSpinner
        }
    ) {
        setSummaryDataByAuction((existingSummaryDataByAuction) => ({
            ...existingSummaryDataByAuction,
            [auctionCode]: {
                auctionCode: auctionCode,
                totalVehicleCount: totalVehicleCount,
                feeMisMatchBuyerFinalize: feeMisMatchBuyerFinalize,
                feeMisMatchNotBuyerFinalize: feeMisMatchNotBuyerFinalize,
                saleKeyList: saleKeyList,
                showSpinner
            }
        }))
    }

    async function handleRefreshAll(event) {
        resetAllData(true)
        const formattedSaleDate = getSaleDateForApi()

        const limit = pLimit(10) // Limits to 10 concurrent requests
        const promises = convertedAuctionsList.map((auctionCode) =>
            limit(() =>
                getFeeIssuesSummary(
                    auctionCode,
                    formattedSaleDate,
                    auditSource,
                    true
                )
                    .then((response) => ({
                        auctionCode: response.auctionCode,
                        totalVehicleCount: response.totalVehicleCount,
                        feeMisMatchBuyerFinalize:
                            response.feeMisMatchBuyerFinalize,
                        feeMisMatchNotBuyerFinalize:
                            response.feeMisMatchNotBuyerFinalize,
                        saleKeyList: response.saleKeyList,
                        showSpinner: false
                    }))
                    .catch((error) => {
                        console.error(
                            `Error fetching Fee Issues Summary Report for auctionCode: ${auctionCode}`,
                            error
                        )
                        return null
                    })
            )
        )

        let newSummaryDataByAuction = { ...summaryDataByAuction }
        for (const promise of promises) {
            promise.then((eachPromiseData) => {
                if (eachPromiseData) {
                    newSummaryDataByAuction[eachPromiseData.auctionCode] =
                        eachPromiseData

                    // Update state inside loop so UI refreshes immediately
                    updateSummaryDataForAuction(eachPromiseData.auctionCode, {
                        totalVehicleCount: eachPromiseData.totalVehicleCount,
                        feeMisMatchBuyerFinalize:
                            eachPromiseData.feeMisMatchBuyerFinalize,
                        feeMisMatchNotBuyerFinalize:
                            eachPromiseData.feeMisMatchNotBuyerFinalize,
                        saleKeyList: eachPromiseData.saleKeyList,
                        showSpinner: false
                    })
                }
            })
        }

        // Promise.all(promises).then((results) => {
        //     const newTableData = results.filter(Boolean).map((result) => ({
        //         auctionCode: result.auctionCode,
        //         totalVehicleCount: result.totalVehicleCount,
        //         feeMisMatchBuyerFinalize: result.feeMisMatchBuyerFinalize,
        //         feeMisMatchNotBuyerFinalize: result.feeMisMatchNotBuyerFinalize
        //     }))
        //
        //     setTableData(newTableData)
        // })
    }

    function refreshSingleAuction(event, auctionCode) {
        updateSummaryDataForAuction(auctionCode, {
            totalVehicleCount: 0,
            feeMisMatchBuyerFinalize: 0,
            feeMisMatchNotBuyerFinalize: 0,
            saleKeyList: [],
            showSpinner: true
        })
        const formattedSaleDate = getSaleDateForApi()
        getFeeIssuesSummary(auctionCode, formattedSaleDate, auditSource, true)
            .then((response) => {
                updateSummaryDataForAuction(auctionCode, {
                    totalVehicleCount: response.totalVehicleCount,
                    feeMisMatchBuyerFinalize: response.feeMisMatchBuyerFinalize,
                    feeMisMatchNotBuyerFinalize:
                        response.feeMisMatchNotBuyerFinalize,
                    saleKeyList: response.saleKeyList,
                    showSpinner: false
                })
            })
            .catch((error) => {
                console.error(
                    `Error fetching Fee Issues Summary Report for auctionCode: ${auctionCode}`,
                    error
                )
            })
    }

    function displayRefreshButton(auctionCode) {
        return (
            <Button
                buttonStyle={'secondary'}
                onClick={(event) => refreshSingleAuction(event, auctionCode)}>
                Refresh
            </Button>
        )
    }

    function handleInputChange(event) {
        switch (event.target.name) {
            case 'saleDate':
                const tempSaleDate = event.target.value?.additionalFormats?.iso
                setSaleDate(tempSaleDate)
                break
            default:
                break
        }
    }

    function displayAuctionInfo(summaryDataByAuction) {
        const auctionCode = summaryDataByAuction.auctionCode
        return (
            <div>
                <div>
                    {auctionDisplayWithCode(
                        auctionCode,
                        staticData.auctionInfoMap
                    )}
                </div>
                {[PAT_ROLE.ADMIN].includes(role) && (
                    <div className="labelMute labelSubText">
                        ({auctionSaleDays[auctionCode]})
                    </div>
                )}
            </div>
        )
    }

    function displaySaleNumbers(summaryDataByAuction) {
        const auctionCode = summaryDataByAuction.auctionCode
        return (
            <div>
                {summaryDataByAuction?.saleKeyList?.length > 0 &&
                    summaryDataByAuction?.saleKeyList.map((eachSaleKey) => {
                        return (
                            <div key={eachSaleKey}>
                                <a
                                    href={`#/boc/feeIssuesReportSearchResults?saleNumber=${eachSaleKey}&auctionCode=${auctionCode}&auditSource=${auditSource}`}>
                                    {eachSaleKey}
                                </a>
                            </div>
                        )
                    })}
            </div>
        )
    }

    function displayTotalVehicleCount(summaryDataForAuction) {
        if (summaryDataForAuction) {
            if (summaryDataForAuction?.showSpinner) {
                return <LocalSpinner message={'Loading...'} />
            } else {
                return summaryDataForAuction.totalVehicleCount
            }
        }
        return null
    }

    function sortByTotalVehicleCount(a, b) {
        return a.totalVehicleCount - b.totalVehicleCount
    }

    return (
        <div>
            <div>
                <div className="flex-container-row">
                    <div>
                        <PatDatePicker
                            name="saleDate"
                            label="Vehicles Sold On"
                            data-testid="datePicker_SaleDate"
                            value={new Date(saleDate)}
                            required
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>&nbsp;&nbsp;</div>
                    <div className="adjustForGridLabel">
                        <Button
                            buttonStyle={'primary'}
                            onClick={handleRefreshAll}>
                            Run Report
                        </Button>
                    </div>
                </div>
            </div>
            <div>&nbsp;</div>
            <Table
                columns={[
                    {
                        className: 'column-auctionCode',
                        render: (item) => <div>{displayAuctionInfo(item)}</div>,
                        title: 'Auction Code',
                        columnSortable: true
                    },
                    {
                        className: 'column-saleNumber',
                        render: (item) => <div>{displaySaleNumbers(item)}</div>,
                        title: 'Sale Number(s)',
                        columnSortable: false
                    },
                    {
                        className: 'column-totalVehicleCount',
                        render: (item) => (
                            <div>{displayTotalVehicleCount(item)}</div>
                        ),
                        title: 'Total Vehicles',
                        columnSortable: true,
                        sorter: (a, b) => sortByTotalVehicleCount(a, b)
                    },
                    {
                        className: 'column-finalized',
                        dataIndex: 'feeMisMatchBuyerFinalize',
                        render: (item) => <div>{item}</div>,
                        title: 'Finalized',
                        columnSortable: false
                    },
                    {
                        className: 'column-notFinalized',
                        dataIndex: 'feeMisMatchNotBuyerFinalize',
                        render: (item) => <div>{item}</div>,
                        title: 'Not Finalized',
                        columnSortable: false
                    },
                    {
                        className: 'column-refresh',
                        dataIndex: 'auctionCode',
                        render: (item) => (
                            <div>{displayRefreshButton(item)}</div>
                        ),
                        title: '',
                        columnSortable: false
                    }
                ]}
                data={getSummaryData()}
                tableLayout={'auto'}
                defaultPageSize={10}
                enablePagination={false}
                id="newFeesTable"
                sortableColumns={true}
                onChangePage={function Ua() {}}
                onChangePageSize={function Ua() {}}
                onChangeSelectedRows={function Ua() {}}
                onChangeSorter={function Ua() {}}
                onExpandClick={function Ua() {}}
                onRowCallback={function Ua() {}}
                onRowSelect={function Ua() {}}
            />
        </div>
    )
}
