import React, { useEffect, useState, useMemo } from 'react'
import { LocationComboBox } from '../common/LocationComboBox'
import { Button } from '@interstate/components/Button'
// import { SelectInput } from '@interstate/components/SelectInput'
import {
    getSaleNumbersByAuction,
    useAuditSourceNames
} from '../../service/auditService'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { PatSelectInput } from '../common/PatSelectInput'
import { PAT_ROLE } from '../../resources/enum.constants'
import { useAuth } from '../../auth/AuthProvider'
import { validateFeeIssuesSearchForm } from './FeeIssuesUtils'
import { PatValidationError } from '../common/PatValidationError'
import { firstLocation } from '../../utils/auction.util'
import { useStaticDataContext } from '../../context/StaticDataContext'

export function FeeIssuesSearchForm() {
    const navigate = useNavigate()
    const staticData = useStaticDataContext()
    const authService = useAuth()
    const principal = authService?.principal
    const userRole = principal?.role
    const defaultLocation = useMemo(
        () => {
            return firstLocation(principal?.locationId)
        },
        [principal?.locationId],
        firstLocation()
    )
    const [locationId, setlocationId] = useState()
    const [saleNumber, setSaleNumber] = useState()
    const [saleNumberOptions, setSaleNumberOptions] = useState()
    const [selectedSource, setSelectedSource] = useState(
        'vehicle-fee-processor-lambda'
    )
    const [errorMessages, setErrorMessages] = useState()

    useEffect(() => {
        if (defaultLocation) {
            const auctionInfoMap = staticData.auctionInfoMap
            const displayableLocationName = `${auctionInfoMap[defaultLocation].state}-${auctionInfoMap[defaultLocation].locationName} (${defaultLocation})`
            setlocationId([
                {
                    value: defaultLocation,
                    label: displayableLocationName
                }
            ])
        }
    }, [staticData, defaultLocation])

    useEffect(() => {
        if (locationId?.length > 0) {
            const auctionCode = locationId[0]?.value
            let _saleNumberOptions = []
            getSaleNumbersByAuction(auctionCode).then((response) => {
                if (response && response?.length > 0) {
                    const saleNumberList = response
                    saleNumberList.forEach((saleNumber) => {
                        _saleNumberOptions.push(saleNumber)
                    })
                }
                setSaleNumberOptions(_saleNumberOptions)
                if (_saleNumberOptions.length > 0) {
                    setSaleNumber(_saleNumberOptions[0])
                }
            })
        }
    }, [locationId])

    const {
        value: testSourcesList,
        loading: testSourcesLoading,
        error: testSourcesError
    } = useAuditSourceNames()

    function handleSearch() {
        const newErrorMessages = validateFeeIssuesSearchForm({
            locationId,
            saleNumber
        })
        if (newErrorMessages) {
            setErrorMessages(newErrorMessages)
            return false
        }
        const queryParams = {
            saleNumber: saleNumber,
            auctionCode: locationId[0]?.value,
            auditSource: selectedSource
        }
        navigate({
            pathname: '/boc/feeIssuesReportSearchResults',
            search: `?${createSearchParams(queryParams)}`
        })
    }
    function handleChange(event) {
        switch (event.target.name) {
            case 'saleNumber':
                setSaleNumber(event.target.value)
                break
            case 'patTestSource':
                setSelectedSource(event.target.value)
                break
            default:
                break
        }
    }
    function handleLocationChange(event) {
        setlocationId(event.target.value)
        setErrorMessages(null)
    }
    function populateSaleNumberOptions() {
        if (saleNumberOptions && saleNumberOptions.length > 0) {
            return saleNumberOptions.map((saleNum) => {
                return { value: saleNum, label: saleNum }
            })
        }
        return [{ value: null, label: '' }]
    }

    function goToSummaryReport() {
        navigate('/boc/feeIssuesSummaryReport')
    }

    return (
        <div>
            <div>
                <LocationComboBox
                    name="locationId"
                    value={locationId}
                    onChange={handleLocationChange}
                    auctionEditFilter={true}
                    multiple={false}
                />
            </div>
            <div>
                {errorMessages?.locationId && (
                    <PatValidationError
                        errorMessage={errorMessages.locationId}
                        data-testid={'alert_ErrorMessages_locationId'}
                    />
                )}
            </div>
            <div>
                <div>
                    <div>
                        <PatSelectInput
                            label="Sale Number"
                            name="saleNumber"
                            className="me-2"
                            overrideBaseCss={true}
                            value={saleNumber}
                            options={populateSaleNumberOptions()}
                            maxHeight={'200px'}
                            displayDeselectOption={false}
                            onChange={handleChange}></PatSelectInput>
                    </div>
                    <div>
                        {errorMessages?.saleNumber && (
                            <PatValidationError
                                errorMessage={errorMessages.saleNumber}
                                data-testid={'alert_ErrorMessages_saleNumber'}
                            />
                        )}
                    </div>
                </div>
            </div>

            {!testSourcesLoading &&
                !testSourcesError &&
                userRole === PAT_ROLE.ADMIN && (
                    <PatSelectInput
                        label="Test Source"
                        name="patTestSource"
                        className="me-2"
                        overrideBaseCss={true}
                        options={testSourcesList.map((source) => {
                            return { value: source, label: source }
                        })}
                        value={selectedSource}
                        maxHeight={'200px'}
                        onChange={handleChange}></PatSelectInput>
                )}
            <div>&nbsp;</div>
            <div className="flex-container-row">
                <div className="fcr-item">
                    <Button buttonStyle={'primary'} onClick={handleSearch}>
                        Search
                    </Button>
                </div>
                <div className="fcr-item">
                    {userRole === PAT_ROLE.ADMIN && (
                        <Button
                            buttonStyle={'secondary'}
                            onClick={goToSummaryReport}>
                            Summary Report
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}
