import React, { useMemo } from 'react'

export function FeeIssuesCount({
    vehicleAuditSearchResults,
    showBuyerFinalize,
    showSellerFinalize,
    showNotFinalized,
    showFinalized,
    showTotalVehicles
}) {
    function feeHasMismatch(feeList) {
        const mismatchFeeExist = feeList.some(
            (auditFee) =>
                auditFee.patCalculatedFee !== auditFee.as400CalculatedFee
        )
        return mismatchFeeExist
    }

    const totalVehiclesCount = useMemo(() => {
        return vehicleAuditSearchResults?.length
    }, [vehicleAuditSearchResults])

    const buyerFinalizeCount = useMemo(() => {
        const issueVehicles = new Set()
        if (vehicleAuditSearchResults?.length > 0 && showBuyerFinalize) {
            vehicleAuditSearchResults.forEach((singleVehicleAuditSearch) => {
                if (singleVehicleAuditSearch.buyerFinalize === 'Y') {
                    if (feeHasMismatch(singleVehicleAuditSearch?.feeList)) {
                        issueVehicles.add(singleVehicleAuditSearch.sblu)
                    }
                }
            })
        }
        return issueVehicles.size
    }, [vehicleAuditSearchResults, showBuyerFinalize])

    const sellerFinalizeCount = useMemo(() => {
        const issueVehicles = new Set()
        if (vehicleAuditSearchResults?.length > 0 && showSellerFinalize) {
            vehicleAuditSearchResults.forEach((singleVehicleAuditSearch) => {
                if (singleVehicleAuditSearch.scode === 'SF') {
                    if (feeHasMismatch(singleVehicleAuditSearch?.feeList)) {
                        issueVehicles.add(singleVehicleAuditSearch.sblu)
                    }
                }
            })
        }
        return issueVehicles.size
    }, [vehicleAuditSearchResults, showSellerFinalize])

    const notFinalizedCount = useMemo(() => {
        const issueVehicles = new Set()
        if (vehicleAuditSearchResults?.length > 0 && showNotFinalized) {
            vehicleAuditSearchResults.forEach((singleVehicleAuditSearch) => {
                if (
                    singleVehicleAuditSearch.scode !== 'SF' &&
                    singleVehicleAuditSearch.buyerFinalize !== 'Y'
                ) {
                    if (feeHasMismatch(singleVehicleAuditSearch?.feeList)) {
                        issueVehicles.add(singleVehicleAuditSearch.sblu)
                    }
                }
            })
        }
        return issueVehicles.size
    }, [vehicleAuditSearchResults, showNotFinalized])

    const finalizedCount = useMemo(() => {
        const issueVehicles = new Set()
        if (vehicleAuditSearchResults?.length > 0 && showFinalized) {
            vehicleAuditSearchResults.forEach((singleVehicleAuditSearch) => {
                if (
                    singleVehicleAuditSearch.scode === 'SF' ||
                    singleVehicleAuditSearch.buyerFinalize === 'Y'
                ) {
                    if (feeHasMismatch(singleVehicleAuditSearch?.feeList)) {
                        issueVehicles.add(singleVehicleAuditSearch.sblu)
                    }
                }
            })
        }
        return issueVehicles.size
    }, [vehicleAuditSearchResults, showFinalized])

    function showHighlight(count) {
        if (count > 0) {
            return 'softbox softboxRed'
        }
        return 'softbox'
    }

    return (
        <div>
            {showNotFinalized && (
                <div className="softbox">
                    <div>Not Finalized</div>
                    <div className="label20 boldLabel">{notFinalizedCount}</div>
                </div>
            )}
            {showFinalized && (
                <div className={showHighlight(finalizedCount)}>
                    <div>Finalized</div>
                    <div className="label20 boldLabel">{finalizedCount}</div>
                </div>
            )}
            {showBuyerFinalize && (
                <div className={showHighlight(buyerFinalizeCount)}>
                    <div>Buyer Finalized</div>
                    <div className="label20 boldLabel">
                        {buyerFinalizeCount}
                    </div>
                </div>
            )}
            {showSellerFinalize && (
                <div className={showHighlight(sellerFinalizeCount)}>
                    <div>Seller Finalized</div>
                    <div className="label20 boldLabel">
                        {sellerFinalizeCount}
                    </div>
                </div>
            )}

            {showTotalVehicles && (
                <div className="softbox softboxWhite">
                    <div>Total Vehicles</div>
                    <div className="label20 boldLabel">
                        {totalVehiclesCount}
                    </div>
                </div>
            )}
        </div>
    )
}
