import { Container } from '../components/Container'

export default function AuthError() {
    return (
        <Container>
            <div>
                <h1>Authentication Error</h1>
                <p>You don't have access and/or a valid role for PAT</p>
                <a href="/auth/sso">SSO Login</a>
            </div>
        </Container>
    )
}
