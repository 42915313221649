import { React } from 'react'
import { CSVLink } from 'react-csv'

export function FeeIssuesCsvExport({ csvData, fileName }) {
    return (
        <>
            {csvData && csvData.feeIssuesListForCSV?.length > 0 && (
                <CSVLink
                    data={csvData.feeIssuesListForCSV}
                    headers={csvData.csvHeaderKeys}
                    filename={fileName}
                    className="ids-btn"
                    data-ids-variant="primary">
                    Export CSV
                </CSVLink>
            )}
        </>
    )
}
