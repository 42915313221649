import { Routes, Route } from 'react-router-dom'
import { EditFeeScheduleView } from '../views/EditFeeScheduleView'
import { ReviewFeesView } from '../views/ReviewFeesView'
import { BocSearchView } from '../views/BocSearchView'
import { FeeResultsView } from '../views/FeeResultsView'
import { RateCardsView } from '../views/RateCardsView'
import { RateCardsResultsView } from '../views/RateCardsResultsView'
import { FeeScheduleStatusView } from '../views/FeeScheduleStatusView'
import { FeeScheduleStatusResultsView } from '../views/FeeScheduleStatusResultsView'
import { EditFeeScheduleConfirmation } from '../views/EditFeeScheduleConfirmation'
import { RoleWrapper } from '../components/RoleWrapper'
import { PAT_ROLE } from '../resources/enum.constants'
import { SearchTransactionsView } from '../views/SearchTransactionsView'
import { SearchTransactionDetailsView } from '../views/SearchTransactionDetailsView'
import { CalculateFeesView } from '../views/CalculateFeesView'
import { FeeIssuesReportSearchView } from '../views/FeeIssuesReportSearchView'
import { FeeIssuesReportSearchResultsView } from '../views/FeeIssuesReportSearchResultsView'
import { FeeIssuesSummaryReportView } from '../views/FeeIssuesSummaryReportView'

function BocRoute() {
    const roles = [
        PAT_ROLE.BOC_EDIT,
        PAT_ROLE.AUCTION_EDIT,
        PAT_ROLE.PRICING_EDIT,
        PAT_ROLE.ADMIN
    ]
    return (
        <Routes>
            {/*Todo: Consider combining search input and results into a single page*/}
            <Route exact path="searchFee" element={<BocSearchView />} />
            <Route exact path="searchFeeResults" element={<FeeResultsView />} />
            <Route
                exact
                path="addFeeSchedule"
                element={
                    <RoleWrapper roles={roles} alert={true}>
                        <EditFeeScheduleView />
                    </RoleWrapper>
                }
            />
            <Route
                exact
                path="feeScheduleStatus"
                element={<FeeScheduleStatusView />}
            />
            <Route
                exact
                path="feeScheduleStatusResults"
                element={<FeeScheduleStatusResultsView />}
            />
            <Route exact path="reviewFees" element={<ReviewFeesView />} />
            <Route
                exact
                path="feeScheduleConfirmation"
                element={<EditFeeScheduleConfirmation />}
            />
            <Route exact path="feeResults" element={<FeeResultsView />} />
            <Route
                exact
                path="searchTransactions"
                element={<SearchTransactionsView />}
            />
            <Route
                exact
                path="searchTransactionDetails"
                element={<SearchTransactionDetailsView />}
            />
            <Route
                exact
                path="feeIssuesReportSearch"
                element={<FeeIssuesReportSearchView />}
            />
            <Route
                exact
                path="feeIssuesReportSearchResults"
                element={<FeeIssuesReportSearchResultsView />}
            />
            <Route
                exact
                path="feeIssuesSummaryReport"
                element={<FeeIssuesSummaryReportView />}
            />
            <Route exact path="ratecards" element={<RateCardsView />} />
            <Route
                exact
                path="ratecardResults"
                element={<RateCardsResultsView />}
            />
            <Route exact path="getFees" element={<RateCardsResultsView />} />
            <Route exact path="calculateFees" element={<CalculateFeesView />} />
            <Route path=" " element={<div>Root BOC</div>} />
        </Routes>
    )
}

export default BocRoute
