import React from 'react'
import { PageHeader } from '../components/PageHeader'
import { Container } from '../components/Container'
import { FeeIssuesSearchForm } from '../components/feeissues/FeeIssuesSearchForm'

export function FeeIssuesReportSearchView() {
    return (
        <Container>
            <PageHeader titleValue="Fee Reconciliation Report" />
            <FeeIssuesSearchForm />
        </Container>
    )
}
