import { useAsyncValue } from '../utils/CustomHooksUtil'
import axios from 'axios'

export function useAuditSourceNames() {
    return useAsyncValue(() => {
        const endPoint = `/api/audit/testSources`
        return axios.get(endPoint).then(({ data }) => {
            return data.filter((source) => source != null)
        })
    }, [])
}

export function useAuditRowById(auditId) {
    return useAsyncValue(() => {
        const endPoint = `/api/audit/id/${auditId}`
        return axios.get(endPoint).then(({ data }) => data)
    }, [auditId])
}

export function useAuditSearch({
    testSource,
    sblu,
    locationId,
    accounts,
    altGroupCodes,
    groupCodes,
    universalKey,
    workOrder,
    vin,
    hasSaleDate,
    onlySoldScode,
    refresh
}) {
    let query = {
        testSource,
        sblu,
        locationId,
        accounts,
        altGroupCodes,
        groupCodes,
        universalKey,
        workOrder,
        vin,
        hasSaleDate,
        onlySoldScode
    }

    return useAsyncValue(() => {
        if (!query) {
            return Promise.resolve([])
        }
        const endPoint = `/api/audit/testSourceByName`
        return axios.post(endPoint, query).then(({ data }) => data)
    }, [
        testSource,
        sblu,
        locationId,
        accounts,
        altGroupCodes,
        groupCodes,
        universalKey,
        workOrder,
        vin,
        hasSaleDate,
        onlySoldScode,
        refresh
    ])
}

export async function getAuditRecords({
    testSource,
    sblu,
    locationId,
    accounts,
    altGroupCodes,
    groupCodes,
    universalKey,
    workOrder,
    hideSpinner
}) {
    let query = {
        testSource,
        sblu,
        locationId,
        accounts,
        altGroupCodes,
        groupCodes,
        universalKey,
        workOrder
    }

    if (!query) {
        return Promise.resolve([])
    }
    const endPoint = `/api/audit/testSourceByName`
    return axios
        .post(endPoint, query, { headers: { hideSpinner } })
        .then(({ data }) => data)
}

export async function getSaleNumbersByAuction(auctionCode) {
    if (auctionCode) {
        const endPoint = `/api/saleNumbersByAuction/${auctionCode}`
        return axios.get(endPoint).then(({ data }) => data)
    } else {
        return Promise.resolve([])
    }
}

export async function getFeeIssuesSummary(
    auctionCode,
    saleDate,
    auditSource,
    hideSpinner
) {
    const endPoint = `/api/audit/feeIssuesSummary/${saleDate}/${auctionCode}/${auditSource}`
    return axios
        .get(endPoint, { headers: { hideSpinner } })
        .then(({ data }) => data)
}
