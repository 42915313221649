import React, { useEffect, useState } from 'react'
import { Modal } from '@interstate/components/Modal'
import { PatDatePicker } from '../common/PatDatePicker'
import { Grid } from '@interstate/components/Grid'
import { useAuth } from '../../auth/AuthProvider'
import { TextArea } from '@interstate/components/TextArea'
import { debounce } from 'lodash'
import { PAT_ROLE } from '../../resources/enum.constants'
import {
    addDaysToToday,
    getDateFromIsoDateInFormat,
    isDateAfterToday,
    isDateBeforeToday
} from '../../utils/date.helper.util'
import { AccessControl } from '../AccessControl'
import {
    getMaxStartDate,
    getSelectedFeeScheduleFlatList
} from './search.results.helper'

export function EditEndDatePopupModal({
    showEditEndDatePopupModal,
    setShowEditEndDatePopupModal,
    saveEditFeeSchedule,
    searchResultsTableData
}) {
    const authService = useAuth()
    const [endDate, setEndDate] = useState()
    const [description, setDescription] = useState()

    let actionCount = 0
    if (searchResultsTableData) {
        const selectedFeeScheduleFlat = getSelectedFeeScheduleFlatList(
            searchResultsTableData
        )
        actionCount = selectedFeeScheduleFlat.length
    }

    useEffect(() => {
        // if the modal is opened, we need to reset the state
        if (showEditEndDatePopupModal) {
            setEndDate(undefined)
            setDescription('')
        }
    }, [showEditEndDatePopupModal])

    function getMinEndDate() {
        const selectedFeeScheduleFlatList = getSelectedFeeScheduleFlatList(
            searchResultsTableData
        )
        if (searchResultsTableData && selectedFeeScheduleFlatList?.length > 0) {
            const maxStartDate = getMaxStartDate(selectedFeeScheduleFlatList)
            // if maxStartDate is before today, we need to set the minDate to yesterday,
            // this is allowed only for BOC role
            if (isDateBeforeToday(maxStartDate, 'YYYY-MM-DD')) {
                if (authService.principal?.role === PAT_ROLE.BOC_EDIT) {
                    return addDaysToToday(-1)
                } else {
                    return new Date()
                }
            } else if (isDateAfterToday(maxStartDate, 'YYYY-MM-DD')) {
                // datePicker is adjusting for timezone etc, so we need to set the time to 23:59:59
                // so that we maintain the same maxStartDate
                return new Date(maxStartDate + 'T23:59:59Z')
            }
        }
    }

    function getMaxEndDate() {
        if (authService.principal?.role === PAT_ROLE.AUCTION_EDIT) {
            return 14
        }
        return null
    }

    function handleInputChange(event) {
        switch (event.target.name) {
            case 'endDate':
                setEndDate(event.target.value)
                break
            case 'description':
                setDescription(event.target.value)
                break
            default:
                break
        }
    }

    function populateEndDate() {
        // even though we already have jsDate in endDate, we need to pass it through moment to ignore the time part
        // otherwise, the datepicker will show the date as the day before
        if (endDate && endDate.additionalFormats) {
            return getDateFromIsoDateInFormat(
                endDate?.additionalFormats.iso,
                'YYYY-MM-DD'
            )
        }
        return undefined
    }
    function getPrimariyLabel() {
        return <AccessControl>Confirm</AccessControl>
    }

    function submitEdit() {
        saveEditFeeSchedule({
            endDate: populateEndDate(),
            description
        })
    }

    return (
        <Modal
            size="small"
            show={showEditEndDatePopupModal}
            header={<div>Edit Fee Schedule</div>}
            footer={{
                primary: {
                    label: getPrimariyLabel(),
                    action: () => submitEdit(),
                    datatestid: 'button_EditFeeSchedule_Confirm'
                },
                options: [
                    {
                        label: 'Cancel',
                        action: () => {
                            setShowEditEndDatePopupModal(false)
                        },
                        datatestid: 'button_EditFeeSchedule_Cancel'
                    }
                ]
            }}>
            <div>
                {actionCount > 1 && (
                    <h3>{actionCount}&nbsp;Fee Schedules will be updated</h3>
                )}
                <Grid item xs={12} data-testid="container_DatePicker_EndDate">
                    <PatDatePicker
                        name="endDate"
                        label="End Date"
                        data-testid="datePicker_EndDate"
                        minDate={getMinEndDate()}
                        maxDays={getMaxEndDate()}
                        onChange={handleInputChange}
                        value={endDate}
                        errorMessage="Valid End Date is requried"
                        onClearCallback={() => setEndDate(undefined)}
                    />
                </Grid>
                <Grid item xs={12} data-testid="container_TextArea_Description">
                    {/* For now, we're limiting description to 30 chars, because that's the AS400 limit. The new backend allows 128. */}
                    <TextArea
                        name="description"
                        label="Description"
                        data-testid="textarea_Description"
                        rows="3"
                        cols="100"
                        onChange={debounce(handleInputChange, 500)}
                        value={description}
                    />
                </Grid>
            </div>
        </Modal>
    )
}
