import moment from 'moment'

export const dollars = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact'
})
export const dollarsFull = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    trailingZeroDisplay: 'stripIfInteger'
})
export const shortDate = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
})

export const renderShortDate = (dateString) => {
    if (!dateString) {
        return '-'
    }
    const date = new Date(dateString)
    return date.getFullYear() === 9999 ? '-' : shortDate.format(date)
}

export const renderDateFromIsoString = (dateString, format) => {
    if (!dateString) {
        return ''
    }
    return moment(dateString).format(format)
}

export const renderDateFromString = (dateString, readFormat, outputFormat) => {
    if (!dateString) {
        return ''
    }
    return moment(dateString, readFormat).format(outputFormat)
}

export const renderFeeStructureType = (type) => {
    switch (type) {
        case 'FlatRate':
            return 'Flat Rate'
        default:
            return type
    }
}

export const describePercentOrAdds = (fees) => {
    //todo: verify that fees can have EITHER addFeesAmount + feeUnit, OR feePercent

    const percentOrAdds =
        fees.addFeesAmount && fees.feeUnit
            ? `${dollars.format(fees.addFeesAmount)} per ${dollars.format(fees.feeUnit)}`
            : fees.feePercent
              ? `${fees.feePercent}%`
              : ''

    let result = ''
    if (percentOrAdds?.length > 0) {
        result = `${percentOrAdds}`
    }
    if (fees.salesPriceLimit) {
        result += `, after sale price ${dollars.format(fees.salesPriceLimit)}`
    }
    if (fees.feeMaximum) {
        result += `, max fee ${dollars.format(fees.feeMaximum)}`
    }

    return result
}

// export const describeMaxFee = (fees) => {
//     let result = null
//     if (fees.feeMaximum) {
//         result = `, max fee ${dollars.format(fees.feeMaximum)}`
//     }
//     return result
// }

const oldEmptyHighValue = 500000
const emptyHighValue = 999999

//todo: standardize on simpler new format?
export const describeFeeStructureOldFormat = (fees) => {
    const priceTiers = fees.priceTier
    const disregard = (tier, previous) =>
        previous?.highLimit === emptyHighValue ||
        (tier.highLimit === emptyHighValue &&
            previous?.highLimit === oldEmptyHighValue)
    const { count, minPrice, maxPrice } = priceTiers.reduce(
        (acc, v) => {
            return {
                count:
                    v.highLimit > acc.maxHighLimit &&
                    !disregard(v, acc.previous)
                        ? acc.count + 1
                        : acc.count,
                minPrice: Math.min(acc.minPrice, v.fee),
                maxPrice: Math.max(
                    acc.maxPrice,
                    !disregard(v, acc.previous) ? v.fee : 0
                ),
                maxHighLimit: Math.max(acc.maxHighLimit, v.highLimit),
                previous: v
            }
        },
        {
            count: 0,
            minPrice: Number.MAX_SAFE_INTEGER,
            maxPrice: 0,
            maxHighLimit: 0
        }
    )

    const percentOrAdds = describePercentOrAdds(fees)

    const tierDescription =
        count === 0 || (maxPrice === 0 && percentOrAdds.length)
            ? ''
            : count === 1
              ? `Flat Fee: ${dollars.format(minPrice)}`
              : `${count} Tiers: ${dollars.format(minPrice)} - ${dollars.format(maxPrice)}`

    const addendum = percentOrAdds.length ? `, plus ${percentOrAdds}` : ''

    const fullDescription = tierDescription.length
        ? `${tierDescription}${addendum}`
        : `${percentOrAdds}`

    return fullDescription
}

// export const describeFeeStructureNewFormat = (fees) => {
//     const priceTiers = fees.feeTiers
//     const disregard = (tier, previous) =>
//         previous?.highLimit === emptyHighValue ||
//         (tier.high === emptyHighValue && previous?.high === oldEmptyHighValue)
//     const { count, minFee, maxFee } = priceTiers.reduce(
//         (acc, v) => {
//             return {
//                 count:
//                     v.high > acc.maxHigh && !disregard(v, acc.previous)
//                         ? acc.count + 1
//                         : acc.count,
//                 minFee: Math.min(acc.minFee, v.fee),
//                 maxFee: Math.max(
//                     acc.maxFee,
//                     !disregard(v, acc.previous) ? v.fee : 0
//                 ),
//                 maxHigh: Math.max(acc.maxHigh, v.high),
//                 previous: v
//             }
//         },
//         { count: 0, minFee: Number.MAX_SAFE_INTEGER, maxFee: 0, maxHigh: 0 }
//     )
//
//     const tierDescription =
//         count === 0 || (maxFee === 0 && percentOrAdds.length)
//             ? ''
//             : count === 1
//               ? `Flat Fee: ${dollars.format(minFee)}`
//               : `${count} Tiers: ${dollars.format(minFee)} - ${dollars.format(maxFee)}`
//
//     const percentOrAdds = describePercentOrAdds(fees)
//     const addendum = percentOrAdds.length ? `, plus ${percentOrAdds}` : ''
//
//     const fullDescription = tierDescription.length
//         ? `${tierDescription}${addendum}`
//         : `${percentOrAdds}`
//
//     return fullDescription
// }

export const describeFeeStructureTierFormat = (feeStructure) => {
    let feeDescription = ''
    if (feeStructure.feeTiers) {
        let tierCount = feeStructure.feeTiers.length
        if (tierCount === 1) {
            feeDescription = `Flat Fee: ${dollars.format(feeStructure.feeTiers[0].fee)}`
        } else if (tierCount > 1) {
            feeDescription = `${tierCount} Tier - ${dollars.format(feeStructure.feeTiers[0].fee)}`
        }
    }
    return feeDescription
}

export const mapRateCardToFeeStructure = (rateCard) => {
    // console.log("Mapping rate card to fee structure: ", rateCard)
    return {
        type: rateCard.type,
        salesPriceLimit: rateCard.salesPriceLimit,
        addFeesAmount: rateCard.addFeesAmount,
        feeUnit: rateCard.feeUnit,
        feeMaximum: rateCard.feeMaximum,
        feePercent: rateCard.feePercent,
        feeTiers: rateCard.priceTier.map((t, i) => ({
            lowLimit: t.lowLimit,
            highLimit:
                t.highLimit === 500000 && i === rateCard.priceTier.length
                    ? 999999
                    : t.highLimit,
            fee: t.fee
        }))
    }
}

export const addSpacesToLongString = (str, maxLength) => {
    let result = ''
    for (let iLoop = 0; iLoop < str?.length; iLoop += maxLength) {
        result += str.substring(iLoop, iLoop + maxLength) + ' '
    }
    return result.trim()
}
