import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { CheckBox } from '@interstate/components/CheckBox'
import { Button } from '@interstate/components/Button'
import { Menu, useMenu } from '@interstate/components/Menu'
import {
    searchResultsActionmenuItems,
    isAllSearchResultsSelected,
    getAvailableOptions,
    deleteIndex,
    expireIndex,
    copyIndex,
    editIndex,
    getSelectedFeeScheduleFlatList
} from './search.results.helper'

export function SearchResultsBulkAction({
    handleSelectAll,
    searchResultsTableData,
    setShowDeleteFeeModal,
    setShowExpireFeeModal,
    setShowEditEndDatePopupModal,
    copyFeeSchedule,
    userRole
}) {
    const [selectAll, setSelectAll] = useState(false)
    const menuReferenceProps = useMenu()
    const menuItems = searchResultsActionmenuItems

    const selectedCount = useMemo(() => {
        return getSelectedFeeScheduleFlatList(searchResultsTableData).length
    }, [searchResultsTableData])

    const handleActionDelete = useCallback(() => {
        setShowDeleteFeeModal(true)
    }, [setShowDeleteFeeModal])

    const handleActionExpire = useCallback(() => {
        setShowExpireFeeModal(true)
    }, [setShowExpireFeeModal])

    const handleActionEdit = useCallback(() => {
        setShowEditEndDatePopupModal(true)
    }, [setShowEditEndDatePopupModal])

    const handleActionCopy = useCallback(() => {
        copyFeeSchedule()
    }, [copyFeeSchedule])

    // const handleMenuSelect = useCallback((event) => {
    //     console.log('MenuItemOnSelect', event)
    // }, [])

    const enableAvailableOptions = useCallback(() => {
        menuItems[deleteIndex].disabled = true
        menuItems[deleteIndex].onSelect = (e) => {
            handleActionDelete(e)
        }
        menuItems[expireIndex].disabled = true
        menuItems[expireIndex].onSelect = (e) => {
            handleActionExpire(e)
        }
        menuItems[copyIndex].disabled = true
        menuItems[copyIndex].onSelect = (e) => {
            handleActionCopy(e)
        }
        menuItems[editIndex].disabled = true
        menuItems[editIndex].onSelect = (e) => {
            handleActionEdit(e)
        }

        const { showDelete, showExpire, showCopy, showEdit } =
            getAvailableOptions(false, searchResultsTableData, userRole)
        if (showDelete) {
            menuItems[deleteIndex].disabled = false
        }
        if (showExpire) {
            menuItems[expireIndex].disabled = false
        }
        if (showCopy) {
            menuItems[copyIndex].disabled = false
        }
        if (showEdit) {
            menuItems[editIndex].disabled = false
        }
    }, [
        searchResultsTableData,
        userRole,
        handleActionDelete,
        handleActionExpire,
        handleActionEdit,
        handleActionCopy,
        menuItems
    ])

    useEffect(() => {
        if (isAllSearchResultsSelected(searchResultsTableData)) {
            setSelectAll(true)
        } else {
            setSelectAll(false)
        }
        enableAvailableOptions()
    }, [searchResultsTableData, enableAvailableOptions])

    function selectAllChange(event) {
        const checked = event.target.value.checked
        setSelectAll(checked)
        handleSelectAll(checked)
    }

    return (
        <div className="flex-container-row fcr-valign-center searchResultsBulkAction">
            <div className="fcr-item">
                {selectedCount > 0 && (
                    <>
                        <span>{selectedCount}</span>
                        <span> selected</span>
                    </>
                )}
                &nbsp;&nbsp;
                <Button
                    onClick={menuReferenceProps.onClick}
                    size={menuItems.length}
                    ref={menuReferenceProps.setAnchorEl}>
                    Action
                </Button>
                <Menu
                    referenceProps={menuReferenceProps.referenceProps}
                    menuOptions={menuItems}
                    size={menuItems.length}
                />
            </div>
            <div className="fcr-item">
                &nbsp;
                <CheckBox
                    onChange={selectAllChange}
                    name="selectAll"
                    checked={selectAll}
                />
            </div>
        </div>
    )
}
