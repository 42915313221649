import React from 'react'
import { getSelectedFeeScheduleFlatList } from './search.results.helper'

export function ActionableFeeInfo({
    searchResultsTableData,
    deleteMode = false,
    expireMode = false
}) {
    let singleSelectedFeeScheduleFlat = null
    let actionCount = 0
    if (searchResultsTableData) {
        const selectedFeeScheduleFlat = getSelectedFeeScheduleFlatList(
            searchResultsTableData
        )
        if (selectedFeeScheduleFlat.length === 1) {
            singleSelectedFeeScheduleFlat = selectedFeeScheduleFlat[0]
        } else {
            actionCount = selectedFeeScheduleFlat.length
        }
    }
    return (
        <div>
            {actionCount === 1 && deleteMode && (
                <h3>Fee Schedule will be deleted</h3>
            )}
            {actionCount === 1 && expireMode && (
                <h3>Fee Schedule will be set to expire today</h3>
            )}
            {actionCount > 1 && deleteMode && (
                <h3>{actionCount}&nbsp;Fee Schedules will be deleted</h3>
            )}
            {actionCount > 1 && expireMode && (
                <h3>
                    {actionCount}&nbsp;Fee Schedule will be set to expire today
                </h3>
            )}
            {singleSelectedFeeScheduleFlat && (
                <div>
                    <p>
                        Fee Schedule Type:{' '}
                        {singleSelectedFeeScheduleFlat.contractType}
                    </p>
                    <p>Auction: {singleSelectedFeeScheduleFlat.buCode}</p>
                    <p>Customer: {singleSelectedFeeScheduleFlat.customer}</p>
                    <p>Sale Type: {singleSelectedFeeScheduleFlat.saleType}</p>
                    <p>Fee Type: {singleSelectedFeeScheduleFlat.feeType}</p>
                </div>
            )}
        </div>
    )
}
