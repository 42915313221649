import React, { useEffect, useMemo, useState } from 'react'
import { useAuditSearch } from '../../service/auditService'
import { FeeIssuesCount } from './FeeIssuesCount'
import { FeeIssuesReportTable } from './FeeIssuesReportTable'

export function FeeIssuesSearchResults({
    auctionCode,
    saleNumber,
    auditSource
}) {
    const locationIdArray = useMemo(() => {
        return [auctionCode]
    }, [auctionCode])
    const auditSearchParams = {
        testSource: auditSource,
        locationId: locationIdArray,
        universalKey: saleNumber,
        hasSaleDate: true,
        onlySoldScode: true
    }
    const {
        value: auditSearchResults,
        loading: auditSearchResultsLoading,
        error: auditSearchResultsError
    } = useAuditSearch(auditSearchParams)
    const [vehicleAuditSearchResults, setVehicleAuditSearchResults] = useState(
        []
    )

    useEffect(() => {
        // console.log('auditSearchResults', auditSearchResults?.length)
        let tempAuditSearchResults = auditSearchResults || []

        tempAuditSearchResults = tempAuditSearchResults.map((item) => {
            item['key'] = item.sblu + item.feeType
            return item
        })

        let groupedByVehicleAuditSearchResults = {}
        tempAuditSearchResults.forEach((item) => {
            let key = item.sblu + item.buCode
            if (groupedByVehicleAuditSearchResults[key]) {
                groupedByVehicleAuditSearchResults[key].push(item)
            } else {
                groupedByVehicleAuditSearchResults[key] = [item]
            }
        })

        // convert to auditSearchResults for table
        let newVehicleAuditSearchResults = []
        for (let key in groupedByVehicleAuditSearchResults) {
            const auditRecordsListForVehicle =
                groupedByVehicleAuditSearchResults[key]
            let newAuditRecordForVehicle = {
                id: auditRecordsListForVehicle[0].id,
                workOrderNumber: auditRecordsListForVehicle[0].workOrderNumber,
                saleDate: auditRecordsListForVehicle[0].saleDate,
                saleNumber: auditRecordsListForVehicle[0].saleNumber,
                runNumber: auditRecordsListForVehicle[0].runNumber,
                lane: auditRecordsListForVehicle[0].lane,
                scode: auditRecordsListForVehicle[0].scode,
                buyerFinalize: auditRecordsListForVehicle[0].buyerFinalize,
                buyer: auditRecordsListForVehicle[0].buyer,
                seller: auditRecordsListForVehicle[0].seller,
                vin: auditRecordsListForVehicle[0].vin,
                sblu: auditRecordsListForVehicle[0].sblu,
                saleType: auditRecordsListForVehicle[0].saleType
            }

            let feeList = []
            auditRecordsListForVehicle.forEach((auditRecord) => {
                let newFee = {
                    feeType: auditRecord.feeType,
                    feeCategory: auditRecord.feeCategory,
                    as400CalculatedFee: auditRecord.as400CalculatedFee,
                    patCalculatedFee: auditRecord.patCalculatedFee,
                    writeBack: auditRecord.writeBack
                }
                feeList.push(newFee)
            })
            newAuditRecordForVehicle.feeList = feeList
            newVehicleAuditSearchResults.push(newAuditRecordForVehicle)
        }
        setVehicleAuditSearchResults(newVehicleAuditSearchResults)
        // end auditSearchResults for table
    }, [auditSearchResults])

    return (
        <div>
            <div>
                <span>
                    This report highlights vehicles where the buyer and/or
                    seller fees calculated by PAT differ from those is AS400
                </span>
            </div>
            <div>&nbsp;</div>
            {!auditSearchResultsLoading && !auditSearchResultsError && (
                <div>
                    <div className="flex-container-row">
                        <div>
                            <FeeIssuesCount
                                vehicleAuditSearchResults={
                                    vehicleAuditSearchResults
                                }
                                showBuyerFinalize={false}
                                showSellerFinalize={false}
                                showNotFinalized={true}
                                showFinalized={false}
                                showTotalVehicles={false}
                            />
                        </div>
                        <div>&nbsp;&nbsp;&nbsp;</div>
                        <div>
                            <FeeIssuesCount
                                vehicleAuditSearchResults={
                                    vehicleAuditSearchResults
                                }
                                showBuyerFinalize={false}
                                showSellerFinalize={false}
                                showNotFinalized={false}
                                showFinalized={true}
                                showTotalVehicles={false}
                            />
                        </div>
                        <div>&nbsp;&nbsp;&nbsp;</div>
                        <div>
                            <FeeIssuesCount
                                vehicleAuditSearchResults={
                                    vehicleAuditSearchResults
                                }
                                showBuyerFinalize={true}
                                showSellerFinalize={false}
                                showNotFinalized={false}
                                showFinalized={false}
                                showTotalVehicles={false}
                            />
                        </div>
                        <div>&nbsp;&nbsp;&nbsp;</div>
                        <div>
                            <FeeIssuesCount
                                vehicleAuditSearchResults={
                                    vehicleAuditSearchResults
                                }
                                showBuyerFinalize={false}
                                showSellerFinalize={true}
                                showNotFinalized={false}
                                showFinalized={false}
                                showTotalVehicles={false}
                            />
                        </div>
                        <div>&nbsp;&nbsp;&nbsp;</div>
                        <div>
                            <FeeIssuesCount
                                vehicleAuditSearchResults={
                                    vehicleAuditSearchResults
                                }
                                showBuyerFinalize={false}
                                showSellerFinalize={false}
                                showNotFinalized={false}
                                showFinalized={false}
                                showTotalVehicles={true}
                            />
                        </div>
                    </div>

                    <div>
                        <FeeIssuesReportTable
                            vehicleAuditSearchResults={
                                vehicleAuditSearchResults
                            }
                            auctionCode={auctionCode}
                            saleNumber={saleNumber}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
