import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../auth/AuthProvider'
import MenuLayout from './navigation/MenuLayout'
import { StaticDataContextWrapper } from '../context/StaticDataContext'
import { Grid } from '@interstate/components/Grid'

function ProtectedLayout() {
    const authService = useAuth()

    if (
        !authService.isAuthenticated() &&
        authService?.principal?.role.length > 0
    ) {
        return <Navigate to="/autherror" />
    }

    return (
        <>
            <header className="App-header">
                <MenuLayout />
            </header>
            <div className="container">
                <Grid maxWidth={'1400px'} marginX={'auto'}>
                    <StaticDataContextWrapper>
                        <Outlet />
                    </StaticDataContextWrapper>
                </Grid>
            </div>
        </>
    )
}

export default ProtectedLayout
