import {
    isDateAfterToday,
    isDateBeforeToday,
    isDateEqualToday
} from '../../utils/date.helper.util'
import { FEE_SCHEDULE_TYPE, PAT_ROLE } from '../../resources/enum.constants'

export const deleteIndex = 0
export const expireIndex = 1
export const copyIndex = 2
export const editIndex = 3
export const searchResultsActionmenuItems = [
    {
        label: 'Delete',
        id: '001',
        value: 'ActionDelete',
        disabled: true,
        divider: false,
        onSelect: (e) => console.log('MenuItemOnSelect', e)
    },
    {
        label: 'Expire',
        id: '002',
        value: 'ActionExpire',
        disabled: true,
        divider: false,
        onSelect: (e) => console.log('MenuItemOnSelect', e)
    },
    {
        label: 'Copy',
        id: '003',
        value: 'ActionCopy',
        disabled: true,
        divider: false,
        onSelect: (e) => console.log('MenuItemOnSelect', e)
    },
    {
        label: 'Edit',
        id: '004',
        value: 'ActionEdit',
        disabled: true,
        divider: false,
        onSelect: (e) => console.log('MenuItemOnSelect', e)
    }
]

export function isAllSearchResultsSelected(searchResultsTableData) {
    const editableSearchResultsTableData = searchResultsTableData.filter(
        (item) => item.editableByUser
    )
    // if there are no editable items, return false
    if (editableSearchResultsTableData.length > 0) {
        return editableSearchResultsTableData.every((item) => item.selectedFee)
    } else {
        return false
    }
}

function canUserEditFeeSchedule(feeScheduleFlat, userRole) {
    // only admin and bocTeam can edit all fee schedules.
    // auctionTeam can edit LocalDeals.
    // pricingTeam can edit Defaults.
    // view cannot edit anything.
    if (userRole === PAT_ROLE.ADMIN || userRole === PAT_ROLE.BOC_EDIT) {
        return true
    } else if (userRole === PAT_ROLE.AUCTION_EDIT) {
        if (feeScheduleFlat.contractType === FEE_SCHEDULE_TYPE.LOCAL_DEALS) {
            return true
        }
    } else if (userRole === PAT_ROLE.PRICING_EDIT) {
        if (feeScheduleFlat.contractType === FEE_SCHEDULE_TYPE.DEFAULTS) {
            return true
        }
    }
    return false
}

function isExpireOptionAvailable(feeScheduleFlat, userRole) {
    // feeScheduleFlat can be expired only if startDate is lessThan today,
    // and endDate is greaterThan today or null
    if (
        canUserEditFeeSchedule(feeScheduleFlat, userRole) &&
        isDateBeforeToday(feeScheduleFlat.startDate, 'YYYY-MM-DD') &&
        (feeScheduleFlat.endDate === null ||
            isDateAfterToday(feeScheduleFlat.endDate, 'YYYY-MM-DD'))
    ) {
        return true
    }
    return false
}

function isDeleteOptionAvaialble(feeScheduleFlat, userRole) {
    // only admin and bocTeam can delete feeSchedules that have startDate today or in the future.
    // auctionTeam, pricingTeam can delete feeSchedles that is starting in future.
    // view cannot delete anything.
    if (userRole === PAT_ROLE.ADMIN || userRole === PAT_ROLE.BOC_EDIT) {
        if (
            canUserEditFeeSchedule(feeScheduleFlat, userRole) &&
            (isDateAfterToday(feeScheduleFlat.startDate, 'YYYY-MM-DD') ||
                isDateEqualToday(feeScheduleFlat.startDate, 'YYYY-MM-DD'))
        ) {
            return true
        }
    } else if (
        userRole === PAT_ROLE.AUCTION_EDIT ||
        userRole === PAT_ROLE.PRICING_EDIT
    ) {
        if (
            canUserEditFeeSchedule(feeScheduleFlat, userRole) &&
            isDateAfterToday(feeScheduleFlat.startDate, 'YYYY-MM-DD')
        ) {
            return true
        }
    }
    return false
}

export function getSelectedFeeScheduleFlatList(searchResultsTableData) {
    return searchResultsTableData?.filter(
        (feeScheduleFlat) =>
            feeScheduleFlat.editableByUser && feeScheduleFlat.selectedFee
    )
}

export function getSingleSelectedFeeScheduleFlat(searchResultsTableData) {
    return searchResultsTableData.find(
        (feeScheduleFlat) =>
            feeScheduleFlat.editableByUser && feeScheduleFlat.selectedFee
    )
}

export function getMatchingFeeScheduleFlatFromList(feeScheduleFlatList, id) {
    return feeScheduleFlatList.find(
        (feeScheduleFlat) => feeScheduleFlat.id === id
    )
}

export function getMaxStartDate(searchResultsTableData) {
    searchResultsTableData.sort((a, b) => {
        return new Date(b.startDate) - new Date(a.startDate)
    })
    return searchResultsTableData[0].startDate
}

export function getAvailableOptions(
    disableAll,
    searchResultsTableData,
    userRole
) {
    let showDelete = false
    let showExpire = false
    let showCopy = false
    let showEdit = false
    let selectedCount = getSelectedFeeScheduleFlatList(
        searchResultsTableData
    ).length

    if (selectedCount === 1) {
        const selectedFeeScheduleFlat = getSingleSelectedFeeScheduleFlat(
            searchResultsTableData
        )
        if (isExpireOptionAvailable(selectedFeeScheduleFlat, userRole)) {
            showExpire = true
        }
        if (canUserEditFeeSchedule(selectedFeeScheduleFlat, userRole)) {
            showEdit = true
        }
        if (isDeleteOptionAvaialble(selectedFeeScheduleFlat, userRole)) {
            showDelete = true
        }
        showCopy = true
    } else if (selectedCount > 1) {
        const selectedFeeScheduleFlatList = getSelectedFeeScheduleFlatList(
            searchResultsTableData
        )
        // check for bulk expire
        if (
            selectedFeeScheduleFlatList.every((feeScheduleFlat) =>
                isExpireOptionAvailable(feeScheduleFlat, userRole)
            )
        ) {
            showExpire = true
        }
        // check for bulk Edit
        if (
            selectedFeeScheduleFlatList.every((feeScheduleFlat) =>
                canUserEditFeeSchedule(feeScheduleFlat, userRole)
            )
        ) {
            showEdit = true
        }

        if (
            selectedFeeScheduleFlatList.every((feeScheduleFlat) =>
                isDeleteOptionAvaialble(feeScheduleFlat, userRole)
            )
        ) {
            showDelete = true
        }
    }

    return {
        showDelete,
        showExpire,
        showCopy,
        showEdit
    }
}
