import React, { useCallback, useEffect, useState } from 'react'
import { Table } from '@interstate/components/Table'
import { sortTableData, getFeeIssuesDataForCSV } from './FeeIssuesUtils'
import { PatSelectInput } from '../common/PatSelectInput'
import { createSearchParams } from 'react-router-dom'
import { Grid } from '@interstate/components/Grid'
import { FeeIssuesCsvExport } from './FeeIssuesCsvExport'
import { renderDateFromString } from '../../utils/Formatters'

export function FeeIssuesReportTable({
    vehicleAuditSearchResults,
    auctionCode,
    saleNumber
}) {
    const buyerFeeCategories = ['BUYER', 'SIMBUYER']
    const sellerFeeCategories = ['SELLER', 'SIMSELLER']
    const transactionStatusList = [
        'All',
        'Not Finalized',
        'Finalized',
        'Buyer Finalized',
        'Seller Finalized'
    ]
    const [transactionStatus, setTransactionStatus] = useState('All')
    const [tableData, setTableData] = useState([])
    const [tableDataForCSV, setTableDataForCSV] = useState([])

    function hasMismatchFees(feeList) {
        return feeList.some(
            (eachFee) => eachFee.patCalculatedFee !== eachFee.as400CalculatedFee
        )
    }

    const updateTableData = useCallback(() => {
        let newTableData = []
        vehicleAuditSearchResults?.forEach((singleVehicleAuditSearch) => {
            let showVehicle = false
            if (transactionStatus === 'All') {
                showVehicle = hasMismatchFees(singleVehicleAuditSearch?.feeList)
            } else if (transactionStatus === 'Finalized') {
                showVehicle =
                    (singleVehicleAuditSearch.buyerFinalize === 'Y' ||
                        singleVehicleAuditSearch.scode === 'SF') &&
                    hasMismatchFees(singleVehicleAuditSearch?.feeList)
            } else if (transactionStatus === 'Not Finalized') {
                showVehicle =
                    (singleVehicleAuditSearch.buyerFinalize !== 'Y' ||
                        !singleVehicleAuditSearch.buyerFinalize) &&
                    singleVehicleAuditSearch.scode !== 'SF' &&
                    hasMismatchFees(singleVehicleAuditSearch?.feeList)
            } else if (transactionStatus === 'Buyer Finalized') {
                showVehicle =
                    singleVehicleAuditSearch.buyerFinalize === 'Y' &&
                    hasMismatchFees(singleVehicleAuditSearch?.feeList)
            } else if (transactionStatus === 'Seller Finalized') {
                showVehicle =
                    singleVehicleAuditSearch.scode === 'SF' &&
                    hasMismatchFees(singleVehicleAuditSearch?.feeList)
            }

            if (showVehicle) {
                newTableData.push(singleVehicleAuditSearch)
            }
        })

        newTableData.sort(sortTableData)
        return newTableData
    }, [vehicleAuditSearchResults, transactionStatus])

    useEffect(() => {
        let newTableData = updateTableData(transactionStatus)
        setTableData(newTableData)
        let tableDataForCSV = getFeeIssuesDataForCSV(newTableData)
        setTableDataForCSV(tableDataForCSV)
    }, [transactionStatus, updateTableData])

    function handleChange(event) {
        switch (event.target.name) {
            case 'transactionStatus':
                const newTransactionStatus = event.target.value
                setTransactionStatus(newTransactionStatus)
                break

            default:
                break
        }
    }

    function displaySaleDate(singleVehicle) {
        return renderDateFromString(
            singleVehicle.saleDate,
            'YYYY-MM-DD',
            'MM/DD/YYYY'
        )
    }

    function displayLaneRun(singleVehicle) {
        // return `${singleVehicle[0].lane}-${singleVehicle[0].runNumber}`
        const queryParams = {
            auditId: singleVehicle.id
        }
        return (
            <a
                href={`#/boc/searchTransactionDetails?${createSearchParams(queryParams)}`}>
                {singleVehicle.lane}-{singleVehicle.runNumber}
            </a>
        )
    }

    function displayMisMatchedFees(eachFee) {
        return (
            <div>
                <table className="patSubTable">
                    <tr>
                        <td>Type:</td>
                        <td>{eachFee.feeType}</td>
                    </tr>
                    <tr>
                        <td>PAT:</td>
                        <td>{eachFee.patCalculatedFee}</td>
                    </tr>
                    <tr>
                        <td>AS400:</td>
                        <td>{eachFee.as400CalculatedFee}</td>
                    </tr>
                </table>
                {/*<div>Fee Type: {eachFee.feeType}</div>*/}
                {/*<div>*/}
                {/*    Pat Fee / AS400 Fee: {eachFee.patCalculatedFee} /{' '}*/}
                {/*    {eachFee.as400CalculatedFee}*/}
                {/*</div>*/}
                <div>&nbsp;</div>
            </div>
        )
    }

    function displayBuyerMisMatchedFees(feeList) {
        return feeList.map((eachFee) => {
            if (buyerFeeCategories.indexOf(eachFee.feeCategory) > -1) {
                if (eachFee.patCalculatedFee !== eachFee.as400CalculatedFee) {
                    return displayMisMatchedFees(eachFee)
                }
            }
            return <></>
        })
    }

    function displaySellerMisMatchedFees(feeList) {
        return feeList.map((eachFee) => {
            if (sellerFeeCategories.indexOf(eachFee.feeCategory) > -1) {
                if (eachFee.patCalculatedFee !== eachFee.as400CalculatedFee) {
                    return displayMisMatchedFees(eachFee)
                }
            }
            return <></>
        })
    }

    function displaySellerFinalized(scode) {
        return scode === 'SF' ? 'Y' : ''
    }

    function getCsvFileName() {
        return `${auctionCode}_${saleNumber}_FeeReconciliation.csv`
    }

    return (
        <div>
            <div>&nbsp;</div>
            <div>
                <PatSelectInput
                    label="Transaction Status"
                    name="transactionStatus"
                    className="me-2"
                    overrideBaseCss={true}
                    value={transactionStatus}
                    options={transactionStatusList.map(
                        (singleTransactionStatus) => {
                            return {
                                value: singleTransactionStatus,
                                label: singleTransactionStatus
                            }
                        }
                    )}
                    maxHeight={'200px'}
                    displayDeselectOption={false}
                    onChange={handleChange}></PatSelectInput>
            </div>
            <Grid
                container
                justifyContent="space-between"
                marginBottom="15px"
                marginTop="15px">
                <Grid item></Grid>
                <Grid item>
                    <FeeIssuesCsvExport
                        csvData={tableDataForCSV}
                        fileName={getCsvFileName()}
                    />
                </Grid>
            </Grid>
            <div>&nbsp;</div>
            <Table
                columns={[
                    {
                        className: 'column-saleDate',
                        render: (item) => <div>{displaySaleDate(item)}</div>,
                        title: 'Sale Date',
                        columnSortable: true
                    },
                    {
                        className: 'column-LaneRun',
                        render: (item) => <div>{displayLaneRun(item)}</div>,
                        title: 'Lane-Run',
                        columnSortable: true
                    },
                    {
                        className: 'column-SaleType',
                        dataIndex: 'saleType',
                        render: (item) => <div>{item}</div>,
                        title: 'Sale Type',
                        columnSortable: true
                    },
                    {
                        className: 'column-workOrder',
                        dataIndex: 'workOrderNumber',
                        render: (item) => <div>{item}</div>,
                        title: 'Work Order',
                        columnSortable: true
                    },
                    {
                        className: 'column-vin',
                        dataIndex: 'vin',
                        render: (item) => <div>{item}</div>,
                        title: 'VIN',
                        columnSortable: true
                    },
                    {
                        className: 'column-scode',
                        dataIndex: 'scode',
                        render: (item) => <div>{item}</div>,
                        title: 'Scode',
                        columnSortable: true
                    },
                    {
                        className: 'column-buyerFinalize',
                        dataIndex: 'buyerFinalize',
                        render: (item) => <div>{item}</div>,
                        title: 'Buyer Finalized',
                        columnSortable: true
                    },
                    {
                        className: 'column-sellerFinalize',
                        dataIndex: 'scode',
                        render: (item) => (
                            <div>{displaySellerFinalized(item)}</div>
                        ),
                        title: 'Seller Finalized',
                        columnSortable: true
                    },
                    {
                        className: 'column-buyer',
                        dataIndex: 'buyer',
                        render: (item) => <div>{item}</div>,
                        title: 'Buyer',
                        columnSortable: true
                    },
                    {
                        className: 'column-seller',
                        dataIndex: 'seller',
                        render: (item) => <div>{item}</div>,
                        title: 'Seller',
                        columnSortable: true
                    },
                    {
                        className: 'column-buyerFeeMismatch',
                        dataIndex: 'feeList',
                        render: (item) => (
                            <div>{displayBuyerMisMatchedFees(item)}</div>
                        ),
                        title: 'Buyer Fee',
                        columnSortable: false
                    },
                    {
                        className: 'column-sellerFeeMismatch',
                        dataIndex: 'feeList',
                        render: (item) => (
                            <div>{displaySellerMisMatchedFees(item)}</div>
                        ),
                        title: 'Seller Fee',
                        columnSortable: false
                    }
                ]}
                data={tableData}
                tableLayout={'auto'}
                defaultPageSize={10}
                enablePagination={false}
                id="newFeesTable"
                sortableColumns={true}
                onChangePage={function Ua() {}}
                onChangePageSize={function Ua() {}}
                onChangeSelectedRows={function Ua() {}}
                onChangeSorter={function Ua() {}}
                onExpandClick={function Ua() {}}
                onRowCallback={function Ua() {}}
                onRowSelect={function Ua() {}}
            />
        </div>
    )
}
