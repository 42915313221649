import { useAuditSourceNames } from '../../service/auditService'
import { React, useEffect, useState } from 'react'
import axios from 'axios'
import { Container } from '../../components/Container'
import { PageHeader } from '../../components/PageHeader'
import { Button } from '@interstate/components/Button'
import { Box } from '@interstate/components/Box'
import { Table } from '@interstate/components/Table'
import { AskToDelete } from '../../components/AskTo'
import { PatErrorAlert } from '../../components/common/PatErrorAlert'
import { IndividualAudit } from '../../components/audit/IndividualAudit'
import { PatTextInput } from '../../components/common/PatTextInput'
import { LocationComboBox } from '../../components/common/LocationComboBox'
import { PatSelectInput } from '../../components/common/PatSelectInput'
import { Grid } from '@interstate/components/Grid'

export default function AuditTestSources() {
    const {
        value: testSourcesList,
        loading: testSourcesLoading,
        error: testSourcesError
    } = useAuditSourceNames()

    // console.log('testSourcesList', testSourcesList)
    const [selectedSource, setSelectedSource] = useState(
        'vehicle-fee-processor-lambda'
    )
    const [year, setYear] = useState(null)
    const [saleNumber, setSaleNumber] = useState(null)
    const [locationId, setlocationId] = useState(null)
    const [selectedAudit, setSelectedAudit] = useState(null)
    const [data, setData] = useState([])
    const [mapData, setMapData] = useState([])
    const [errorData, setErrorData] = useState([])
    const [counters, setCounters] = useState({})
    const [totalIncorrect, setTotalIncorrect] = useState(0)
    const [showTestSourceDeleteModal, setShowTestSourceDeleteModal] =
        useState(false)

    useEffect(() => {
        if (selectedSource === null && !testSourcesLoading) {
            setSelectedSource(testSourcesList[0] || null)
        }
    }, [testSourcesList, testSourcesLoading, selectedSource])

    const handleInputChange = (e) => {
        setSelectedSource(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const query = {}
        if (year) {
            query.year = year
        }
        if (saleNumber) {
            query.saleNumber = saleNumber
        }
        if (locationId) {
            query.locationId = locationId[0].value
        }
        if (selectedSource) {
            query.testSource = selectedSource
        }
        const params = new URLSearchParams(query)
        axios
            .get(`/api/audit/testSourceByName?${params.toString()}`)
            .then((response) => {
                let data = response.data
                // data = data.sort((a, b) => a.feeCategory - b.feeCategory);
                data = data.map((item) => {
                    item['key'] = item.sblu + item.feeType
                    return item
                })
                // data = data.map((item) => {item['sellerG'] = `${item.sellerGroup || ''} | ${item.sellerSubGroup || ''}`; return item;})
                // data = data.map((item) => {item['buyerG'] = `${item.buyerGroup || ''} | ${item.buyerSubGroup || ''}`; return item;})
                let ndata = {}
                data.forEach((item) => {
                    let key = item.sblu + item.buCode
                    if (ndata[key]) {
                        ndata[key].push(item)
                    } else {
                        ndata[key] = [item]
                    }
                })
                setMapData(ndata)
                setData(data)

                let counter = {}
                let incorrect = 0
                let errorKeys = {}
                data.forEach((item) => {
                    if (item.as400CalculatedFee !== item.patCalculatedFee) {
                        if (!counter.hasOwnProperty(item.feeCategory)) {
                            counter[item.feeCategory] = 0
                        }
                        counter[item.feeCategory] =
                            counter[item.feeCategory] + 1
                        incorrect++
                        let key = item.sblu + item.buCode
                        errorKeys[key] = mapData[key]
                    }
                })
                setCounters(counter)
                setTotalIncorrect(incorrect)
                setErrorData(errorKeys)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    function deleteTest(e) {
        e.preventDefault()
        if (
            selectedSource === 'vehicle-fee-processor-lambda' ||
            selectedSource === 'vehicle-fee-processor-lambda_NO_CUSTOMER'
        ) {
            return false
        }
        setShowTestSourceDeleteModal(true)
    }

    function confirmDeleteTestSource() {
        axios
            .delete(`/api/audit/testSourceByName?testSource=${selectedSource}`)
            .then((response) => {
                window.location.reload()
                setShowTestSourceDeleteModal(false)
            })
    }

    return (
        <Container>
            <PageHeader titleValue="Audit Test Sources" />
            <PatErrorAlert error={testSourcesError} />
            <Grid container spacing={2}>
                <Grid xs={12} md={4}>
                    <LocationComboBox
                        value={locationId}
                        onChange={(e) => {
                            setlocationId(e.target.value)
                        }}
                        multiple={false}
                    />
                </Grid>
                <Grid xs={12} md={4}>
                    <PatTextInput
                        label="Sale Number"
                        value={saleNumber}
                        onChange={(e) => {
                            setSaleNumber(e.target.value)
                        }}
                    />
                </Grid>
                <Grid xs={12} md={4}>
                    <PatTextInput
                        label="Year"
                        value={year}
                        onChange={(e) => {
                            setYear(e.target.value)
                        }}
                    />
                </Grid>
            </Grid>
            {!testSourcesLoading && (
                <PatSelectInput
                    label="Test Source"
                    name="Test Source"
                    className="me-2"
                    overrideBaseCss={true}
                    options={testSourcesList.map((source) => {
                        return { value: source, label: source }
                    })}
                    value={selectedSource}
                    maxHeight={'200px'}
                    onChange={handleInputChange}></PatSelectInput>
            )}
            <Box marginTop="10px">
                <Button onClick={handleSubmit} sx={{ marginRight: '10px' }}>
                    Submit
                </Button>
                <Button buttonStyle="secondary" onClick={deleteTest}>
                    Delete
                </Button>
            </Box>

            <AskToDelete
                show={showTestSourceDeleteModal}
                cancel={() => setShowTestSourceDeleteModal(false)}
                handle={confirmDeleteTestSource}
                data={{ testSource: selectedSource }}
                attribute={'testSource'}
            />

            {data.length > 0 && (
                <div>
                    {Object.keys(counters).map((key) => (
                        <h2 key={key}>
                            {key}: {counters[key]}{' '}
                        </h2>
                    ))}
                    <h2>
                        Total Incorrect: {totalIncorrect} / {data.length} ={' '}
                        {((totalIncorrect / data.length) * 100).toFixed(2)} %
                    </h2>
                </div>
            )}

            {!selectedAudit && (
                <>
                    {Object.keys(errorData).length > 0 && (
                        <Table
                            tableLayout="fixed"
                            // sortableColumns={true}
                            dataDensity="small"
                            // displayFilter={true}
                            // highlightOnHover={true}
                            headerBackgroundColor="dark"
                            rowClassName={'tableBad'}
                            columns={[
                                {
                                    title: 'Index',
                                    render: (item) => (
                                        <span>
                                            <div>
                                                <b>Auction:</b>{' '}
                                                {mapData[item][0]?.buCode}{' '}
                                            </div>
                                            <div
                                                onClick={() => {
                                                    setSelectedAudit(item)
                                                }}>
                                                <b>SBLU:</b>{' '}
                                                {mapData[item][0]?.sblu}
                                            </div>
                                            <div>
                                                <b>WO#:</b>{' '}
                                                {
                                                    mapData[item][0]
                                                        ?.workOrderNumber
                                                }
                                            </div>
                                            <div>
                                                <b>UKEY:</b>{' '}
                                                {mapData[item][0]?.saleYear}-
                                                {mapData[item][0]?.saleNumber}-
                                                {mapData[item][0]?.lane}-
                                                {mapData[item][0]?.runNumber}
                                            </div>
                                        </span>
                                    )
                                },
                                {
                                    title: 'Sale',
                                    render: (item) => (
                                        <span>
                                            <div>
                                                <b>Fee Schedule Type:</b>{' '}
                                                {mapData[item][0]?.contractType}
                                            </div>
                                            <div>
                                                <b>Sale Type:</b>{' '}
                                                {mapData[item][0]?.saleType}
                                            </div>
                                            <div>
                                                <b>Buyer Table:</b>{' '}
                                                {
                                                    mapData[item][0]
                                                        ?.buyerFeeTable
                                                }
                                            </div>
                                            <div>
                                                <b>Seller Table:</b>{' '}
                                                {
                                                    mapData[item][0]
                                                        ?.sellerFeeTable
                                                }
                                            </div>
                                            <div>
                                                <b>Sale Price: </b>{' '}
                                                {mapData[item][0]?.salePrice}
                                            </div>
                                        </span>
                                    )
                                },
                                {
                                    title: 'Customer',
                                    render: (item) => (
                                        <span>
                                            <div>
                                                <b>Buyer / Group / Alt</b>
                                            </div>
                                            <span>
                                                {mapData[item][0]?.buyer} /{' '}
                                            </span>
                                            <span>
                                                {mapData[item][0]?.buyerGroup} /{' '}
                                            </span>
                                            <span>
                                                {
                                                    mapData[item][0]
                                                        ?.buyerSubGroup
                                                }
                                            </span>
                                            <div>
                                                <b>Seller / Group / Alt</b>
                                            </div>
                                            <span>
                                                {mapData[item][0]?.seller} /{' '}
                                            </span>
                                            <span>
                                                {mapData[item][0]?.sellerGroup}{' '}
                                                /{' '}
                                            </span>
                                            <span>
                                                {
                                                    mapData[item][0]
                                                        ?.sellerSubGroup
                                                }
                                            </span>
                                        </span>
                                    )
                                },
                                {
                                    title: 'Fees',
                                    render: (item) => (
                                        <span>
                                            <div>
                                                <b>(Fee Type) Pat / AS400</b>
                                            </div>
                                            {mapData[item].map((fee) => {
                                                return (
                                                    <div>
                                                        <span>
                                                            {fee.feeType}{' '}
                                                        </span>
                                                        <span>
                                                            {
                                                                fee.patCalculatedFee
                                                            }
                                                        </span>
                                                        <span>
                                                            {' '}
                                                            /{' '}
                                                            {
                                                                fee.as400CalculatedFee
                                                            }
                                                        </span>
                                                    </div>
                                                )
                                            })}
                                        </span>
                                    )
                                }
                            ]}
                            data={Object.keys(errorData)}
                        />
                    )}
                    <Table
                        tableLayout="fixed"
                        // sortableColumns={true}
                        dataDensity="small"
                        // displayFilter={true}
                        // highlightOnHover={true}
                        headerBackgroundColor="dark"
                        rowClassName={(record) => {
                            let reply = 'tableGood'
                            mapData[record]?.forEach((item) => {
                                if (
                                    item.as400CalculatedFee !==
                                    item.patCalculatedFee
                                ) {
                                    reply = 'tableBad'
                                }
                            })
                            return reply
                        }}
                        columns={[
                            {
                                title: 'Index',
                                render: (item) => (
                                    <span>
                                        <div>
                                            <b>Auction:</b>{' '}
                                            {mapData[item][0]?.buCode}{' '}
                                        </div>
                                        <div
                                            onClick={() => {
                                                setSelectedAudit(item)
                                            }}>
                                            <b>SBLU:</b>{' '}
                                            {mapData[item][0]?.sblu}
                                        </div>
                                        <div>
                                            <b>WO#:</b>{' '}
                                            {mapData[item][0]?.workOrderNumber}
                                        </div>
                                        <div>
                                            <b>UKEY:</b>{' '}
                                            {mapData[item][0]?.saleYear}-
                                            {mapData[item][0]?.saleNumber}-
                                            {mapData[item][0]?.lane}-
                                            {mapData[item][0]?.runNumber}
                                        </div>
                                    </span>
                                )
                            },
                            {
                                title: 'Sale',
                                render: (item) => (
                                    <span>
                                        <div>
                                            <b>Contract Type:</b>{' '}
                                            {mapData[item][0]?.contractType}
                                        </div>
                                        <div>
                                            <b>Sale Type:</b>{' '}
                                            {mapData[item][0]?.saleType}
                                        </div>
                                        <div>
                                            <b>Buyer Table:</b>{' '}
                                            {mapData[item][0]?.buyerFeeTable}
                                        </div>
                                        <div>
                                            <b>Seller Table:</b>{' '}
                                            {mapData[item][0]?.sellerFeeTable}
                                        </div>
                                        <div>
                                            <b>Sale Price: </b>{' '}
                                            {mapData[item][0]?.salePrice}
                                        </div>
                                    </span>
                                )
                            },
                            {
                                title: 'Customer',
                                render: (item) => (
                                    <span>
                                        <div>
                                            <b>Buyer / Group / Alt</b>
                                        </div>
                                        <span>
                                            {mapData[item][0]?.buyer} /{' '}
                                        </span>
                                        <span>
                                            {mapData[item][0]?.buyerGroup} /{' '}
                                        </span>
                                        <span>
                                            {mapData[item][0]?.buyerSubGroup}
                                        </span>
                                        <div>
                                            <b>Seller / Group / Alt</b>
                                        </div>
                                        <span>
                                            {mapData[item][0]?.seller} /{' '}
                                        </span>
                                        <span>
                                            {mapData[item][0]?.sellerGroup} /{' '}
                                        </span>
                                        <span>
                                            {mapData[item][0]?.sellerSubGroup}
                                        </span>
                                    </span>
                                )
                            },
                            {
                                title: 'Fees',
                                render: (item) => (
                                    <span>
                                        <div>
                                            <b>(Fee Type) Pat / AS400</b>
                                        </div>
                                        {mapData[item].map((fee) => {
                                            return (
                                                <div>
                                                    <span>{fee.feeType} </span>
                                                    <span>
                                                        {fee.patCalculatedFee}
                                                    </span>
                                                    <span>
                                                        {' '}
                                                        /{' '}
                                                        {fee.as400CalculatedFee}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </span>
                                )
                            }
                        ]}
                        data={Object.keys(mapData)}
                    />
                </>
            )}
            {selectedAudit && (
                <div>
                    <IndividualAudit
                        auditData={mapData[selectedAudit]}
                        back={() => setSelectedAudit(null)}
                    />
                </div>
            )}
        </Container>
    )
}
