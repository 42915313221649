import { isEmptyString } from '../../utils/commonValidations'
import { renderDateFromString } from '../../utils/Formatters'

export function sortTableData(a, b) {
    const obj1SaleDate = new Date(a.saleDate)
    const obj2SaleDate = new Date(b.saleDate)
    const obj1Lane = parseInt(a.lane, 10)
    const obj2Lane = parseInt(b.lane, 10)
    const obj1RunNumber = parseInt(a.runNumber, 10)
    const obj2RunNumber = parseInt(b.runNumber, 10)

    if (obj1SaleDate.getTime() !== obj2SaleDate.getTime()) {
        return obj2SaleDate - obj1SaleDate // Sort by saleDate first
    }
    if (obj1Lane !== obj2Lane) {
        return obj1Lane - obj2Lane // If saleDate is the same, sort by lane
    }
    return obj1RunNumber - obj2RunNumber // If lane is same, sort by runNumber
}

export function validateFeeIssuesSearchForm({ locationId, saleNumber }) {
    let newErrorMessages = {}
    if (!locationId || isEmptyString(locationId)) {
        newErrorMessages.locationId = 'Location is required'
    }
    if (!saleNumber || isEmptyString(saleNumber)) {
        newErrorMessages.saleNumber = 'Sale number is required'
    }

    if (Object.keys(newErrorMessages).length === 0) {
        return null
    }
    return newErrorMessages
}

function getCSVHeaders() {
    const csvHeaderKeys = [
        { key: 'saleDate', label: 'Sale Date' },
        { key: 'lane', label: 'Lane' },
        { key: 'runNumber', label: 'Run' },
        { key: 'saleType', label: 'Sale Type' },
        { key: 'workOrderNumber', label: 'Work Order' },
        { key: 'vin', label: 'Vin' },
        { key: 'scode', label: 'SCode' },
        { key: 'buyerFinalize', label: 'Buyer Finalized' },
        { key: 'sellerFinalize', label: 'Seller Finalized' },
        { key: 'buyer', label: 'Buyer' },
        { key: 'seller', label: 'Seller' },
        { key: 'buyerFee', label: 'Buy Fee' },
        { key: 'as400BuyerFee', label: 'AS400 Buy Fee' },
        { key: 'sellerFee', label: 'Sell Fee' },
        { key: 'as400SellerFee', label: 'AS400 Sell Fee' },
        { key: 'simBuyerFee', label: 'Simulcast Buy Fee' },
        { key: 'as400SimBuyerFee', label: 'AS400 Simulcast Buy Fee' },
        { key: 'simSellerFee', label: 'Simulcast Sell Fee' },
        { key: 'as400SimSellerFee', label: 'AS400 Simulcast Sell Fee' }
    ]
    return csvHeaderKeys
}

export function getFeeIssuesDataForCSV(auditSearchResultList) {
    const feeIssuesListForCSV = []
    if (auditSearchResultList?.length > 0) {
        auditSearchResultList.forEach((singleVehicleAuditSearch) => {
            let newFeeIssue = {
                buyer: singleVehicleAuditSearch.buyer,
                buyerFinalize: singleVehicleAuditSearch.buyerFinalize,
                sellerFinalize:
                    singleVehicleAuditSearch.scode === 'SF' ? 'Y' : '',
                lane: singleVehicleAuditSearch.lane,
                runNumber: singleVehicleAuditSearch.runNumber,
                saleDate: renderDateFromString(
                    singleVehicleAuditSearch.saleDate,
                    'YYYY-MM-DD',
                    'MM-DD-YYYY'
                ),
                saleNumber: singleVehicleAuditSearch.saleNumber,
                saleType: singleVehicleAuditSearch.saleType,
                sblu: singleVehicleAuditSearch.sblu,
                seller: singleVehicleAuditSearch.seller,
                scode: singleVehicleAuditSearch.scode,
                vin: singleVehicleAuditSearch.vin,
                workOrderNumber: singleVehicleAuditSearch.workOrderNumber
            }

            if (singleVehicleAuditSearch.feeList?.length > 0) {
                singleVehicleAuditSearch.feeList.forEach((auditFee) => {
                    if (
                        auditFee.as400CalculatedFee !==
                        auditFee.patCalculatedFee
                    ) {
                        switch (auditFee.feeCategory) {
                            case 'SELLER':
                                newFeeIssue.sellerFee =
                                    auditFee.patCalculatedFee
                                newFeeIssue.as400SellerFee =
                                    auditFee.as400CalculatedFee
                                break
                            case 'BUYER':
                                newFeeIssue.buyerFee = auditFee.patCalculatedFee
                                newFeeIssue.as400BuyerFee =
                                    auditFee.as400CalculatedFee
                                break
                            case 'SIMBUYER':
                                newFeeIssue.simBuyerFee =
                                    auditFee.patCalculatedFee
                                newFeeIssue.as400SimBuyerFee =
                                    auditFee.as400CalculatedFee
                                break
                            case 'SIMSELLER':
                                newFeeIssue.simSellerFee =
                                    auditFee.patCalculatedFee
                                newFeeIssue.as400SimSellerFee =
                                    auditFee.as400CalculatedFee
                                break
                            default:
                                break
                        }
                    }
                })
            }
            feeIssuesListForCSV.push(newFeeIssue)
        })
    }
    return {
        feeIssuesListForCSV,
        csvHeaderKeys: getCSVHeaders()
    }
}
