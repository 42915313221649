import { PAT_ROLE } from '../resources/enum.constants'
export function auctionDisplayUtil(code, context) {
    let display = ''
    let notfound = true
    context?.auctions.every((auction) => {
        if (auction.locationCode === code) {
            display = `${auction.state}-${auction.locationName} (${auction.locationCode})`
            notfound = false
        }
        return notfound
    })
    return display
}

export function auctionDisplayWithCode(code, auctionInfoMap) {
    // console.log('code', code)
    if (auctionInfoMap[code]) {
        return `${code}-${auctionInfoMap[code].locationName}`
    } else {
        return code
    }
}

export function sortByAuctionsUtil(auctions, context, param = 'auctionId') {
    return auctions.sort((a, b) => {
        let fa = auctionDisplayUtil(a[param], context),
            fb = auctionDisplayUtil(b[param], context)

        if (fa < fb) {
            return -1
        }
        if (fa > fb) {
            return 1
        }
        return 0
    })
}

function filterAuctionList(auctions, location) {
    let ids = location.split(',')
    return auctions.filter((a) => ids.includes(a.value))
}

export function auctionOptionsUtil(context, all = false, auth = null) {
    let auctions = context?.auctions
        ?.map((auction) => {
            return {
                value: auction.locationCode,
                text: `${auction.state}-${auction.locationName} (${auction.locationCode})`,
                label: `${auction.state}-${auction.locationName} (${auction.locationCode})`
            }
        })
        .sort((a, b) => a.label.localeCompare(b.label))

    if (auth) {
        if (auth.role === PAT_ROLE.AUCTION_EDIT) {
            return filterAuctionList(auctions, auth.locationId)
        }
        if (auth.role === PAT_ROLE.VIEW_ROLE) {
            if (auth.locationId) {
                return filterAuctionList(auctions, auth.locationId)
            } else return auctions
        }
    }

    if (all) {
        return [{ value: 'ALL', text: 'ALL', label: 'ALL' }, ...auctions]
    } else {
        return auctions
    }
}

export function firstLocation(locationId) {
    if (locationId && locationId.trim().length > 0) {
        return [locationId?.split(',')[0]]
    }
    return null
}

export const wave0Auctions = ['NOAA', 'CADE', 'SKYA']
export const wave1Auctions = ['AAAI', 'NAA', 'NADE', 'NWE']
export const wave2Auctions = [
    'DAA',
    'GCAA',
    'MAAI',
    'MMAA',
    'NSAA',
    'BWAE',
    'DETA',
    'FAA',
    'HAA',
    'PTAF',
    'LAA',
    'LMAA',
    'PRAA',
    'SPAA',
    'SWFL',
    'WPBA',
    'FADA',
    'SCAA',
    'SDAA'
]
export const wave3Auctions = [
    'CINA',
    'INDY',
    'LOUA',
    'OAA',
    'AAAW',
    'NASH',
    'ROME',
    'SONB',
    'VLAA',
    'ALBA',
    'DAAE',
    'PXAA',
    'TCAA',
    'BAA',
    'CLEV',
    'HATA',
    'KEYA',
    'MROC',
    'BIGH',
    'DALA',
    'DFWA',
    'PPAA',
    'SAAA',
    'THAA',
    'DADE',
    'FAAO',
    'JAX'
]
export const wave4Auctions = [
    'AAA',
    'AYCA',
    'CAAI',
    'CNCA',
    'SVAA',
    'WLNC',
    'BCAA',
    'PAA',
    'SSAA',
    'UAA',
    'TBAA',
    'NVAA',
    'MAA',
    'RAA',
    'IOWA',
    'KCAA',
    'MISS',
    'MLRA',
    'OMAA',
    'PCAA',
    'SAPL',
    'SAPT',
    'SLAA',
    'GOAA',
    'ALOH'
]

export const auctionSaleDays = {
    NOAA: 'Wednesday',
    CADE: 'Wednesday',
    SKYA: 'Tuesday',
    NADE: 'Wednesday',
    NWE: 'Thursday',
    AAAI: 'Tuesday',
    NAA: 'Thursday',
    DAA: 'Wednesday',
    GCAA: 'Thursday/Tuesday',
    MAAI: 'Wednesday',
    MMAA: 'Wednesday',
    NSAA: 'Thursday',
    BWAE: 'Tuesday',
    DETA: 'Thursday/Wednesday/Tuesday',
    FAA: 'Thursday',
    HAA: 'Wednesday/Friday',
    PTAF: 'Tuesday',
    LAA: 'Wednesday',
    LMAA: 'Friday',
    PRAA: 'Thursday',
    SPAA: 'Thursday',
    SWFL: 'Wednesday',
    WPBA: 'Thursday/Wednesday',
    FADA: 'Thursday',
    SCAA: 'Thursday/Tuesday',
    SDAA: 'Tuesday',
    CINA: 'Thursday',
    INDY: 'Wednesday',
    LOUA: 'Thursday',
    OAA: 'Tuesday',
    AAAW: 'Tuesday',
    NASH: 'Wednesday/Tuesday',
    ROME: 'Monday',
    SONB: 'Tuesday',
    VLAA: 'Wednesday',
    ALBA: 'Wednesday',
    DAAE: 'Thursday',
    PXAA: 'Thursday',
    TCAA: 'Wednesday',
    BAA: 'Wednesday/Thursday',
    CLEV: 'Wednesday',
    HATA: 'Tuesday',
    KEYA: 'Tuesday',
    MROC: 'Tuesday',
    BIGH: 'Tuesday',
    DALA: 'Wednesday/Tuesday',
    DFWA: 'Thursday',
    PPAA: 'Wednesday',
    SAAA: 'Wednesday',
    THAA: 'Thursday',
    DADE: 'Wednesday',
    FAAO: 'Tuesday',
    JAX: 'Thursday',
    AAA: 'Thursday/Tuesday',
    AYCA: 'Monday',
    CAAI: 'Thursday',
    CNCA: 'Wednesday',
    SVAA: 'Tuesday',
    WLNC: 'Friday',
    BCAA: 'Wednesday',
    PAA: 'Tuesday',
    SSAA: 'Wednesday',
    UAA: 'Wednesday/Monday?',
    TBAA: 'Tuesday/Thursday/Monday?',
    NVAA: 'Friday/Tuesday/Thursday?',
    MAA: 'Friday/Thursday?',
    RAA: 'Tuesday/Thursday',
    IOWA: 'Monday',
    KCAA: 'Wednesday/Tuesday',
    MISS: 'Thursday',
    MLRA: 'Tuesday',
    OMAA: 'Thursday',
    PCAA: 'Tuesday',
    SAPL: 'Thursday',
    SAPT: 'Thursday',
    SLAA: 'Tuesday',
    GOAA: 'Wednesday/Tuesday/Monday?',
    ALOH: 'Wednesday',
    QLM5: 'Wednesday',
    QIM5: 'Wednesday/Tuesday',
    AREN: '-',
    QNM5: 'Monday/Friday',
    QGM5: 'Thursday'
}
