import React from 'react'
import { Alert } from '@interstate/components/Alert'

export function PatValidationError({ errorMessage, dataTestId }) {
    return (
        <Alert
            onCloseClick={function Ua() {}}
            title="Error"
            type="error"
            sx={{ marginBlockEnd: '10px' }}
            data-testid={dataTestId}>
            <span>{errorMessage}</span>
        </Alert>
    )
}
