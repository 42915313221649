import React from 'react'
import { ActionableFeeInfo } from './ActionableFeeInfo'
import { Modal } from '@interstate/components/Modal'
import { AccessControl } from '../AccessControl'

export function DeleteFeeScheduleModal({
    showDeleteFeeModal,
    setShowDeleteFeeModal,
    confirmDeleteFeeSchedule,
    searchResultsTableData
}) {
    function getPrimariyLabel() {
        return <AccessControl>Confirm</AccessControl>
    }
    return (
        <Modal
            data-testid="modal_DeleteFeeSchedule"
            size="small"
            show={showDeleteFeeModal}
            header={
                <ActionableFeeInfo
                    searchResultsTableData={searchResultsTableData}
                    deleteMode={true}></ActionableFeeInfo>
            }
            footer={{
                primary: {
                    label: getPrimariyLabel(),
                    action: () => {
                        confirmDeleteFeeSchedule()
                    },
                    datatestid: 'button_DeleteFeeSchedule_Confirm'
                },
                options: [
                    {
                        label: 'Cancel',
                        action: () => {
                            setShowDeleteFeeModal(false)
                        },
                        datatestid: 'button_DeleteFeeSchedule_Cancel'
                    }
                ]
            }}
        />
    )
}
