import { React, useEffect, useState, useMemo } from 'react'
import { SearchFees } from '../components/search/SearchFees'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
    deleteFeeScheduleList,
    expireFeeScheduleList,
    updateFeeScheduleList,
    useSearchFee
} from '../service/feeScheduleService'
import { PageHeader } from '../components/PageHeader'
import { Container } from '../components/Container'
import { PatErrorAlert } from '../components/common/PatErrorAlert'
import { setStorage } from '../utils/storageService'
import { FEE_SCHEDULE_STORAGE_KEY } from '../utils/constants'
import { getTodayDateInFormat } from '../utils/date.helper.util'
import { ExpireFeeScheduleModal } from '../components/search/ExpireFeeScheduleModal'
import { generateRateCardDescription } from '../utils/rate.card.helper'
import {
    getCustomerTypeFromValue,
    trimAltGroupCode
} from '../utils/customer.helper.util'
import { EditEndDatePopupModal } from '../components/search/EditEndDatePopupModal'
import { EditExpireSuccessFailure } from '../components/search/EditExpireSuccessFailure'
import { DeleteFeeScheduleModal } from '../components/search/DeleteFeeScheduleModal'
// import SearchResultsTable from '../components/search/SearchResultsTable'
import { getFeeScheduleFlatForCSV } from '../utils/fee.schedule.helper'
import { CsvExport } from '../components/search/CsvExport'
import { Grid } from '@interstate/components/Grid'
import { isUserHasAccess } from '../utils/access.control.utils'
import { useStaticDataContext } from '../context/StaticDataContext'
import { useAuth } from '../auth/AuthProvider'
import { SearchResultsBulkAction } from '../components/search/SearchResultsBulkAction'
import {
    getMatchingFeeScheduleFlatFromList,
    getSelectedFeeScheduleFlatList,
    getSingleSelectedFeeScheduleFlat
} from '../components/search/search.results.helper'
import { SearchResultsTableSimple } from '../components/search/SearchResultsTableSimple'
// import { Typography } from '@interstate/components/Typography'

export function FeeResultsView(props) {
    const navigate = useNavigate()
    const staticData = useStaticDataContext()
    const authService = useAuth()
    const principal = authService?.principal
    const userRole = principal?.role

    const [showExpireFeeModal, setShowExpireFeeModal] = useState(false)
    const [showDeleteFeeModal, setShowDeleteFeeModal] = useState(false)
    const [showEditEndDatePopupModal, setShowEditEndDatePopupModal] =
        useState(false)
    const [showSuccessFailureModal, setShowSuccessFailureModal] =
        useState(false)
    const [successMode, setSuccessMode] = useState(false)
    // const [actionableFeeScheduleFlat, setActionableFeeScheduleFlat] = useState()
    const [searchResultsTableData, setSearchResultsTableData] = useState([])
    const [errorMessage] = useState()

    const [queryParams] = useSearchParams()
    let feeScheduleTypes = queryParams.get('feeScheduleTypes')
    let auctionCodes = queryParams.get('auctionCodes')
    let saleTypes = queryParams.get('saleTypes')
    let feeTypes = queryParams.get('feeTypes')
    let accounts = queryParams.get('accounts')
    let groupCodes = queryParams.get('groupCodes')
    let altGroupCodes = queryParams.get('altGroupCodes')
    let startDate = queryParams.get('startDate')
    let endDate = queryParams.get('endDate')
    let description = queryParams.get('description')
    let includeSaleTypeParent = queryParams.get('includeSaleTypeParent')
    let includeDeletedExpired = queryParams.get('includeDeletedExpired')
    let anyCustomersOnly = queryParams.get('anyCustomersOnly')
    let noCustomersOnly = queryParams.get('noCustomersOnly')

    const {
        value: searchFeeResults,
        loading: searchFeeLoading,
        error: searchFeeError
    } = useSearchFee({
        feeScheduleTypes,
        auctionCodes,
        saleTypes,
        feeTypes,
        accounts,
        groupCodes,
        altGroupCodes,
        startDate,
        endDate,
        description,
        includeSaleTypeParent,
        includeDeletedExpired,
        anyCustomersOnly,
        noCustomersOnly
    })

    useEffect(() => {
        // console.log('useEffect searchFeeResults', searchFeeResults)
        if (searchFeeResults && !searchFeeLoading && !searchFeeError) {
            setSearchResultsTableData(searchFeeResults)
        }
    }, [searchFeeResults, searchFeeLoading, searchFeeError])

    let searchResultsFeeScheduleFlatCsv = useMemo(() => {
        return getFeeScheduleFlatForCSV(searchResultsTableData)
    }, [searchResultsTableData])

    // const showExpireFeeSchedule = useCallback((feeStructureFlat) => {
    //     setShowExpireFeeModal(true)
    //     setActionableFeeScheduleFlat(feeStructureFlat)
    // }, [])

    // const showDeleteFeeSchedule = useCallback((feeStructureFlat) => {
    //     setShowDeleteFeeModal(true)
    //     setActionableFeeScheduleFlat(feeStructureFlat)
    // }, [])

    function updateSearchResultsTableData(
        updatedFeeScheduleFlatList,
        expireMode,
        deleteMode
    ) {
        let newSearchResultsTableData = []
        searchResultsTableData.forEach((feeScheduleFlat) => {
            let updatedFeeScheduleFlat = getMatchingFeeScheduleFlatFromList(
                updatedFeeScheduleFlatList,
                feeScheduleFlat.id
            )
            if (updatedFeeScheduleFlat) {
                if (expireMode) {
                    newSearchResultsTableData.push(updatedFeeScheduleFlat)
                } else if (deleteMode) {
                    // do nothing, remove the record from the table
                }
            } else {
                newSearchResultsTableData.push(feeScheduleFlat)
            }
        })
        setSearchResultsTableData(newSearchResultsTableData)
        setSuccessMode(true)
        setShowSuccessFailureModal(true)
    }

    function confirmExpireFeeSchedule() {
        if (!isUserHasAccess(principal?.username, staticData.bffEnv)) {
            return false
        }

        const selectedFeeScheduleFlatList = getSelectedFeeScheduleFlatList(
            searchResultsTableData
        )
        const feeScheduleIdList = selectedFeeScheduleFlatList.map(
            (feeScheduleFlat) => feeScheduleFlat.id
        )

        expireFeeScheduleList(feeScheduleIdList).then(
            (response) => {
                setShowExpireFeeModal(false)
                let expiredFeeScheduleFlatList = response.data
                updateSearchResultsTableData(
                    expiredFeeScheduleFlatList,
                    true,
                    false
                )
            },
            (error) => {
                console.log('error', error)
                setSuccessMode(false)
                setShowSuccessFailureModal(true)
            }
        )
    }

    function confirmDeleteFeeSchedule() {
        if (!isUserHasAccess(principal?.username, staticData.bffEnv)) {
            return false
        }

        const selectedFeeScheduleFlatList = getSelectedFeeScheduleFlatList(
            searchResultsTableData
        )
        const feeScheduleIdList = selectedFeeScheduleFlatList.map(
            (feeScheduleFlat) => feeScheduleFlat.id
        )

        deleteFeeScheduleList(feeScheduleIdList).then(
            (response) => {
                setShowDeleteFeeModal(false)
                let deletedFeeScheduleFlatList = response.data
                updateSearchResultsTableData(
                    deletedFeeScheduleFlatList,
                    false,
                    true
                )
                setSuccessMode(true)
                setShowSuccessFailureModal(true)
            },
            (error) => {
                console.log('error', error)
                setSuccessMode(false)
                setShowSuccessFailureModal(true)
            }
        )
    }

    // const memoizedCopyFeeSchedule = useCallback(copyFeeSchedule, [navigate])

    // const editFeeSchedule = useCallback(
    //     (feeScheduleFlat) => {
    //         if (isDateAfterToday(feeScheduleFlat.startDate, 'YYYY-MM-DD')) {
    //             memoizedCopyFeeSchedule(feeScheduleFlat)
    //         } else {
    //             setActionableFeeScheduleFlat(feeScheduleFlat)
    //             setShowEditEndDatePopupModal(true)
    //         }
    //     },
    //     [memoizedCopyFeeSchedule]
    // )

    /**
     * only enddate and description will be saved in the backend
     * @param feeScheduleFlat
     */
    function saveEditFeeSchedule({ endDate, description }) {
        if (!isUserHasAccess(principal?.username, staticData.bffEnv)) {
            return false
        }

        const selectedFeeScheduleFlatList = getSelectedFeeScheduleFlatList(
            searchResultsTableData
        )
        const feeScheduleIdList = selectedFeeScheduleFlatList.map(
            (feeScheduleFlat) => feeScheduleFlat.id
        )

        updateFeeScheduleList(feeScheduleIdList, endDate, description)
            .then((response) => {
                setSuccessMode(true)
                setShowEditEndDatePopupModal(false)
                setShowSuccessFailureModal(true)
                let updatedFeeScheduleFlatList = response.data
                updateSearchResultsTableData(
                    updatedFeeScheduleFlatList,
                    true,
                    false
                )
            })
            .catch((error) => {
                setSuccessMode(false)
                console.log('error', error)
                setShowEditEndDatePopupModal(false)
                setShowSuccessFailureModal(true)
            })
    }

    function copyFeeSchedule() {
        const feeScheduleFlat = getSingleSelectedFeeScheduleFlat(
            searchResultsTableData
        )
        let rateCardType = 'Tiered'
        if (feeScheduleFlat.feeStructure.feeTiers.length === 1) {
            rateCardType = 'FlatFee'
        }
        let feeTiers = []
        feeScheduleFlat.feeStructure.feeTiers.forEach((tier) => {
            feeTiers.push({
                lowLimit: tier.lowLimit,
                highLimit: tier.highLimit,
                fee: tier.fee
            })
        })

        let clonedFeeSchedule = {
            feeScheduleType: feeScheduleFlat.contractType,
            startDate: new Date().toISOString(),
            description: null,
            saleTypeFeeList: [
                {
                    auctionCodeList: [feeScheduleFlat.buCode],
                    saleType: feeScheduleFlat.saleType,
                    feeCode: feeScheduleFlat.feeType,
                    rateCardType: rateCardType,
                    rateCardDesc: generateRateCardDescription(feeTiers),
                    excludedAuctionList: null,
                    overrides: null,
                    feeStructure: {
                        salesPriceLimit:
                            feeScheduleFlat.feeStructure.salesPriceLimit,
                        addFeesAmount:
                            feeScheduleFlat.feeStructure.addFeesAmount,
                        feeUnit: feeScheduleFlat.feeStructure.feeUnit,
                        feeMaximum: feeScheduleFlat.feeStructure.feeMaximum,
                        feePercent: feeScheduleFlat.feeStructure.feePercent,
                        feeTiers: feeTiers
                    }
                }
            ]
        }

        switch (getCustomerTypeFromValue(feeScheduleFlat.customer)) {
            case 'accountNumber':
                clonedFeeSchedule.accountNumbers = [feeScheduleFlat.customer]
                break
            case 'groupCode':
                clonedFeeSchedule.groupCodes = [feeScheduleFlat.customer]
                break
            case 'altGroupCode':
                let altGroupCode = trimAltGroupCode(feeScheduleFlat.customer)
                clonedFeeSchedule.altGroupCodes = [altGroupCode]
                break
            default:
                break
        }
        setStorage(FEE_SCHEDULE_STORAGE_KEY, JSON.stringify(clonedFeeSchedule))
        navigate('/boc/addFeeSchedule')
    }

    function getCsvFileName() {
        return `SearchResults_Fee_Schedules_${getTodayDateInFormat('MM_DD_YYYY')}.csv`
    }

    function feeSelectChange(feeScheduleFlatId, checked) {
        setSearchResultsTableData((prev) =>
            prev.map((feeScheduleFlat) =>
                feeScheduleFlat.id === feeScheduleFlatId
                    ? { ...feeScheduleFlat, selectedFee: checked }
                    : feeScheduleFlat
            )
        )
    }

    function handleSelectAll(checked) {
        setSearchResultsTableData((prev) =>
            prev.map((feeScheduleFlat) => {
                if (feeScheduleFlat.editableByUser) {
                    return { ...feeScheduleFlat, selectedFee: checked }
                } else {
                    return feeScheduleFlat
                }
            })
        )
    }

    return (
        <Container>
            <PageHeader titleValue="Fee Schedules" />
            {errorMessage && <PatErrorAlert error={true} text={errorMessage} />}
            {/*<div>*/}
            {/*    <h1></h1>*/}
            {/*</div>*/}
            <SearchFees />

            <Grid
                container
                justifyContent="space-between"
                marginBottom="15px"
                marginTop="15px">
                <Grid item></Grid>
                <Grid item>
                    <CsvExport
                        csvData={searchResultsFeeScheduleFlatCsv}
                        fileName={getCsvFileName()}
                    />
                </Grid>
            </Grid>

            <div>
                {!searchFeeLoading && !searchFeeError && (
                    <>
                        <span>
                            Total rows:{' '}
                            <strong>{searchResultsTableData.length}</strong>
                        </span>
                        <div>&nbsp;</div>
                        <SearchResultsBulkAction
                            handleSelectAll={handleSelectAll}
                            searchResultsTableData={searchResultsTableData}
                            setShowDeleteFeeModal={setShowDeleteFeeModal}
                            setShowExpireFeeModal={setShowExpireFeeModal}
                            setShowEditEndDatePopupModal={
                                setShowEditEndDatePopupModal
                            }
                            copyFeeSchedule={copyFeeSchedule}
                            userRole={userRole}
                        />
                        <SearchResultsTableSimple
                            searchResultsTableData={searchResultsTableData}
                            feeSelectChange={feeSelectChange}
                        />
                        {/*<SearchResultsTable*/}
                        {/*    searchResultsTableData={searchResultsTableData}*/}
                        {/*    feeSelectChange={feeSelectChange}*/}
                        {/*/>*/}
                    </>
                )}
            </div>

            <DeleteFeeScheduleModal
                confirmDeleteFeeSchedule={confirmDeleteFeeSchedule}
                setShowDeleteFeeModal={setShowDeleteFeeModal}
                showDeleteFeeModal={showDeleteFeeModal}
                searchResultsTableData={searchResultsTableData}
            />

            <ExpireFeeScheduleModal
                confirmExpireFeeSchedule={confirmExpireFeeSchedule}
                setShowExpireFeeModal={setShowExpireFeeModal}
                showExpireFeeModal={showExpireFeeModal}
                searchResultsTableData={searchResultsTableData}
            />
            <EditEndDatePopupModal
                saveEditFeeSchedule={saveEditFeeSchedule}
                setShowEditEndDatePopupModal={setShowEditEndDatePopupModal}
                showEditEndDatePopupModal={showEditEndDatePopupModal}
                searchResultsTableData={searchResultsTableData}
            />
            <EditExpireSuccessFailure
                showSuccessFailureModal={showSuccessFailureModal}
                setShowSuccessFailureModal={setShowSuccessFailureModal}
                successMode={successMode}
                failureMode={!successMode}
            />
        </Container>
    )
}
