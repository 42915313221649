import React from 'react'
import { useAuth } from '../auth/AuthProvider'
import { useStaticDataContext } from '../context/StaticDataContext'
import { isUserHasAccess } from '../utils/access.control.utils'

export function AccessControl({ children }) {
    const staticData = useStaticDataContext()
    const authService = useAuth()
    const principal = authService?.principal

    if (isUserHasAccess(principal?.username, staticData.bffEnv)) {
        return <>{children}</>
    } else {
        return <div className="labelMute">Placeholder Denied</div>
    }
}
