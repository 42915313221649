import { Navigate, redirect } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useAuth } from '../auth/AuthProvider'
import { Container } from '../components/Container'

export default function SsoView() {
    const authService = useAuth()

    useEffect(() => {
        if (authService.isAuthenticated()) {
            return <Navigate to="/main" />
        } else {
            window.location.href = '/auth/sso'
            return redirect('/auth/sso')
        }
    }, [authService])

    return (
        <Container>
            <div>Redirecting to SSO</div>
        </Container>
    )
}
